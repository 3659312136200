import React, { Component } from 'react';
import { Progress, Button } from 'antd';
import logo from '../assets/logo.png';
import './contract-footer.css';
import si from '../assets/scroll-indicator@2x.png';
class ContractFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prog: 0,
      questions: 10,
      buttonUpDisabled: true,
      buttonDownDisabled: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.prog !== this.state.prog) {
      this.setState({ prog: this.props.prog });
      if (this.props.prog > 0) {
        this.setState({ buttonUpDisabled: false });
      } else {
        this.setState({ buttonUpDisabled: true });
      }

      if (this.props.prog < 10) {
        this.setState({ buttonDownDisabled: false });
      } else {
        this.setState({ buttonDownDisabled: true });
      }
    }
  }

  clickUp = () => {
    var currProg = this.state.prog;
    if (currProg > 0) this.props.animateForm(currProg - 1);
  };

  clickDown = () => {
    var currProg = this.state.prog;
    if (currProg < 10) this.props.animateForm(currProg + 1);
  };

  render() {
    return (
      <div id="c-footer">
        <div id="scroll-indicator">
          <img src={si} alt="scroll-indicator" />
        </div>
        <div className="navigation">
          <button
            className="up-dn"
            onClick={this.clickUp}
            disabled={this.state.buttonUpDisabled === true ? true : false}
          >
            <span className="ss-navigateup" />
          </button>
          <button
            className="up-dn"
            onClick={this.clickDown}
            disabled={this.state.buttonDownDisabled === true ? true : false}
          >
            <span className="ss-navigatedown" />
          </button>
        </div>

        <div className={`continue-btn ${this.props.showContinue}`}>
          <Button className="link darker sign-btn section-continue" onClick={() => this.props.updateProgression()}>
            Continue
          </Button>
          <span className="press">or press Enter</span>
        </div>
        <div className="prog">
          <Progress
            percent={this.state.prog * 10}
            format={() => `Step ${this.state.prog} of ${this.state.questions}`}
          />
        </div>
        <div className="logo-container">
          <img src={logo} className="footer-logo" alt="logo" />
        </div>
      </div>
    );
  }
}
export default ContractFooter;
