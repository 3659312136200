import React, { Component } from 'react';
import AdminRouter from './admin-router';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  componentDidMount = () => {
    document.body.classList.remove('pp');
    document.body.classList.remove('private');
    var token = localStorage.getItem('adminToken');
    let auth = token === null ? false : true;
    this.setState({ isAuthenticated: auth });
    this.addClassToBodyIfLoggedIn();
  };
  componentDidUpdate = () => {
    this.addClassToBodyIfLoggedIn();
  };

  addClassToBodyIfLoggedIn = () => {
    var token = localStorage.getItem('adminToken');
    var body = document.body;
    if (token) {
      body.classList.add('admin');
    } else {
      body.classList.remove('admin');
    }
  };

  signOut = () => {
    this.userHasAuthenticated(false);
    localStorage.clear();
  };

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  signOut = () => {
    localStorage.clear();
    this.userHasAuthenticated(false);
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      signIn: '/admin/sign-in',
      defaultRoute: '/admin',
      signOut: this.signOut,
    };
    return <AdminRouter childProps={childProps} />;
  }
}
export default Admin;
