import React, { useState, useEffect } from 'react';
import Network from '../services/network-requests';
import capitalize from '../../utilities/capitalize';
import { Descriptions } from 'antd';

function Plan(props) {
  const [charge, setCharge] = useState({ charge: 0 });
  const [card, setCard] = useState({ card: 'N/A' });
  const [error, setError] = useState();
  useEffect(() => {
    fetchData();
  }, [props.plan]);

  const fetchData = async () => {
    const result = await Network.get('/admin-charge', props.plan.chargeId);
    if (result.data.error) {
      setError(result.data.error);
    } else {
      setCharge(result.data.charge);
      setCard(result.data.charge.payment_method_details.card);
    }
  };

  const chargeView = () => {
    if (error) {
      return error;
    } else {
      return (
        <Descriptions title={capitalize(props.plan.plan)} border column={{ md: 2, sm: 2, xs: 1 }}>
          <Descriptions.Item label="Amount">${charge.amount * 0.01}</Descriptions.Item>
          <Descriptions.Item label="Status">{charge.status}</Descriptions.Item>
          <Descriptions.Item label="Card">{card.brand}</Descriptions.Item>
          <Descriptions.Item label="Card ending in">{card.last4}</Descriptions.Item>
          <Descriptions.Item label="Charge ID">{charge.id}</Descriptions.Item>
        </Descriptions>
      );
    }
  };

  return <div>{chargeView()}</div>;
}
export default Plan;
