import React, { Component } from 'react';
import PetInfo from './pet-info';
class ContractView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: this.props.contract,
      originator: `${this.props.contract.firstName} ${this.props.contract.lastName}`,
      petName: this.props.contract.petName,
      partner: `${this.props.contract.partnerFirstName} ${this.props.contract.partnerLastName}`,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contract !== this.props.contract) {
      this.setState({ contract: this.props.contract });
    }
  }

  becameMember = () => {
    var str = this.state.contract.becameMember;
    str = str.replace('partner', this.state.partner);
    str = str.replace('originator', this.state.originator);
    return str.replace('petName', this.state.petName);
  };

  customCustody = () => {
    var str = this.state.contract.care;
    // customCustody is a textarea, free form
    if (str === 'customCustody') {
      return this.state.contract.customCustody;
    } else {
      str = str.replace('partner', this.state.partner);
      str = str.replace('originator', this.state.originator);
      str = str.replace('petName', this.state.petName);
      return str;
    }
  };

  care = () => {
    var str = this.state.contract.care;
    // customCare is a textarea, free form
    if (str === 'customCare') {
      return this.state.contract.customCare;
    } else {
      str = str.replace('partner', this.state.partner);
      str = str.replace('originator', this.state.originator);
      str = str.replace(/petName/g, this.state.petName);
      return str;
    }
  };

  custody = () => {
    var str = this.state.contract.custody;
    str = str.replace('originatorDays', this.state.contract.originatorDays);
    str = str.replace('partnerDays', this.state.contract.partnerDays);
    str = str.replace('partner', this.state.partner);
    str = str.replace('originator', this.state.originator);
    return str.replace('petName', this.state.petName);
  };

  holiday = () => {
    var str = this.state.contract.holidays;
    // customHolidays is a textarea, free form
    if (str === 'customHolidays') {
      return this.state.contract.customHolidays;
    } else {
      return this.state.contract.holidays;
    }
  };

  compliance = () => {
    var str = this.state.contract.compliance;
    // customCompliance is a textarea, free form
    if (str === 'customCompliance') {
      return this.state.contract.customCompliance;
    } else {
      return this.state.contract.compliance;
    }
  };

  otherNeeds = () => {
    var str = this.state.contract.otherNeeds;
    // customOtherNeeds is a textarea, free form
    if (str === 'customOtherNeeds') {
      return this.state.contract.customOtherNeeds;
    } else {
      str = str.replace('partner', this.state.partner);
      return str.replace('originator', this.state.originator);
    }
  };

  descriptionOfPet = () => {
    var desc = this.state.contract.breed + ' ' + this.state.contract.species.toLowerCase();
    return desc;
  };

  render() {
    return (
      <div>
        <div className="section">
          <div className="contract-header">Pet ownership and care agreement</div>
          <PetInfo contract={this.state.contract} />
          <h2>Introduction</h2>
          <p>
            Whereas {this.state.originator} and {this.state.partner} are in a relationship and wish to memorialize their
            agreement for how to care for their pet {this.state.petName} in the event of a termination of their
            relationship; and
          </p>
          <p>
            Whereas {this.state.originator} and {this.state.partner} wish to ensure that {this.state.petName} would be
            continued to be cared for properly so that {this.state.petName} may maintain a happy and healthy life; and
          </p>
          <p>
            Whereas {this.state.originator} and {this.state.partner} wish to ensure that this agreement be enforced as
            necessary;
          </p>
          <p>Now, Therefore</p>
          <p>
            {this.state.originator} and {this.state.partner} (jointly referred to as "parties") hereby agree that{' '}
            {this.state.petName}, a {this.descriptionOfPet()} is jointly and severally owned, with rights of
            survivorship, by {this.state.originator} and {this.state.partner}. The parties agree that in the event that
            their relationship ends either through divorce, separation, dissolution of civil union, cessation of
            cohabitation, or other objective action to end the relationship by either party, that the ownership and care
            of {this.state.petName} be governed by this agreement and the parties ask any court tasked with enforcing
            this agreement do so with the spirit and letter of its terms.
          </p>
          <p>
            The parties expressly acknowledge that the covenants of this agreement are supported by good and adequate
            consideration, and that such covenants are reasonable and necessary to protect the life, health, and
            happiness of {this.state.petName}.
          </p>
        </div>
        <div className="section">
          <h2>Initial Ownership</h2>
          <p>{this.becameMember()}</p>
        </div>
        <div className="section">
          <h2>Care for Pet</h2>
          <p>
            The parties agree that, irrespective of who has possession or control of {this.state.petName} that he or she
            will care for the pet in a way that ensures the pet’s welfare needs are being met while taking into account
            the specific needs of the species, breed, and health of the pet.
          </p>
          <p>These standards of care include, but are not limited to, the need:</p>
          <ol>
            <li>For a suitable place to live</li>
            <li>For a suitable diet</li>
            <li>To exhibit normal behavior patterns</li>
            <li>To be housed with or apart from other pets or animals, as applicable</li>
            <li>To be protected from pain, suffering, injury, and disease</li>
            <li>To ensure the owner or purchaser of the pet is over the age of sixteen</li>
            <li>That no pet or animal will be given as a prize to unaccompanied children under the age of sixteen</li>
          </ol>
          <p>
            The parties further agree that at all time in caring for the pet that they will act in the best interests of
            the pet and where appropriate seek expert advice to ensure this is carried out as appropriate for the pet.
            The parties agree that they will both act in a pet-centric manner towards each other, and in particular
            during any period of handover, and both parties shall use their best endeavors not to act in a hostile
            manner towards each other in front of the pet.
          </p>
        </div>
        <div className="section">
          <h2>Ownership After Termination of the Relationship</h2>
          <p>
            Upon the termination of the relationship between {this.state.originator} and {this.state.partner},{' '}
            {this.state.petName} shall be owned as follows:
          </p>
          <p>{this.custody()}</p>
          <p>
            The parties agree that when {this.state.petName} is in the possession of the other party, neither party
            shall deny information on the status, health, or location of {this.state.petName}.
          </p>
          <p>
            The parties agree that they may modify this ownership and visitation arrangement upon their mutual
            agreement, to be in writing.
          </p>
        </div>
        <div className="section">
          <h2>Costs of Pet’s Care</h2>
          <p className="text-bold">Basic Needs</p>
          <p>{this.care()}</p>
          <p className="text-bold">Other Needs </p>
          <p>
            In the event that other, more expensive needs arise for {this.state.petName}, beyond basic necessities (such
            as medical care, medication, etc), the parties agree to split costs as follows:
          </p>
          <p>{this.otherNeeds()}</p>
          <p className="text-bold">Fines and Breach of Law</p>
          <p>
            Any fines imposed by any authority as a result of the breach of any by-laws or legislation under existing
            laws in relation to {this.state.petName}, including where the same is to be housed safely, or caused in
            relation to the control of the animal in a public place, shall be borne by the party who has been guilty of
            the breach or if both have been in control and possession of the pet at the same time, both parties.
          </p>

          <p className="text-bold">Holidays</p>
          <p>The parties agree that {this.state.petName} will be shared on Holidays as follows:</p>

          <p>{this.holiday()}</p>
          <p>The parties agree that they will share the costs for boarding kennels where relevant.</p>
          <p className="text-bold">Veterinary Care</p>
          <p>
            The parties agree that the visits by either of the parties to the vet for non-emergency treatment should be
            agreed between the parties and that each will use their best efforts to consult the other in relation to
            this agreement in order for both parties to attend or authorize any such treatment.
          </p>
          <p>
            In the case of emergency treatment, the party in possession of the pet has the power to make a unilateral
            decision on vet visits where is it no possible or there is no time to contact the other party.
          </p>
          <p>
            In the event that {this.state.petName} becomes terminally ill, both parties agree, if so advised, for the
            pet to be euthanized in the interests and welfare of the pet. In the event that one of the parties is
            unavailable or cannot be contacted, the remaining party will be guided by a licensed veterinarian in the
            giving of such consent in the absence of the other party.
          </p>
          <p>
            Such consent, in any event, will not be unreasonably withheld by either party. Both parties will agree that
            in the event that the pet does have to be euthanized, and that they consider it to be appropriate, that both
            or one of them shall be present with any other family members as they jointly agree.
          </p>

          <p className="text-bold">Liens</p>
          <p>
            Both parties agree that they will not take any steps to take a loan against the value of{' '}
            {this.state.petName} or dispose of {this.state.petName} without the express, written consent of the other.
          </p>
          <p className="text-bold">Compliance</p>
          <p>
            The parties agree that in the event that the matters set out in this agreement are ignored by either party
            to the extent that the lack of compliance amounts to neglect, the pet will revert absolutely to the other
            party and the defaulting party will pay all the costs that were agreed to be paid by them in any event for:{' '}
          </p>
          <p>{this.compliance()}</p>
        </div>
        <div className="section">
          <h2>Other Welfare Matters</h2>
          <p className="text-bold">Accommodation</p>
          <p>
            The parties will take all necessary steps to ensure that they both comply with any necessary steps required
            by a party’s landlord or other housing authority, so that he or she is entitled to keep such pet on the
            premises to avoid any hostility that would otherwise be created towards the pet, or otherwise violate
            housing rules or regulations, and will take such necessary steps to ensure that they control the pet in such
            a way that their occupation of the premises is not withdrawn by the landlord or other housing authority.
          </p>
          <p className="text-bold">Breeding</p>
          <p>
            {this.state.petName} will not be bread without both parties’ consent. Both parties agree that{' '}
            {this.state.petName} will be neutered (if not already), where appropriate for {this.state.petName}
            ’s species, to prevent unplanned pregnancy if the pet is not kept for the purposes of breeding.
          </p>
          <p>
            In the event that {this.state.petName} gives birth to a litter or offspring, the parties agree to sell the
            same, rehoming them responsibly, and split the proceeds equally or in any such proportions as they shall
            agree. Alternatively, if one party wishes to purchase all or any of the litter from the other, the same
            shall be agreed upon the basis set out in this paragraph.
          </p>

          <p className="text-bold">Bargaining Chip</p>
          <p>
            Both parties agree that they will not attempt, or actually use, {this.state.petName} as a pawn or bargaining
            tool between each other during or after the relationship breakdown.
          </p>
        </div>
        <div className="section">
          <h2>Changes to Agreement</h2>
          <p>
            In the event that {this.state.originator} and {this.state.partner} separate, both parties agree that they
            will both continue to meet their respective pet care and financial obligations until the terms of this
            agreement come into force. The parties may agree to sell their interest in {this.state.petName} to the other
            party upon agreement in writing.
          </p>
          <p>
            The parties agree that they will keep personal contact details updated with the other party so the parties
            may contact each other without delay or disruption.
          </p>
          <p>
            In the event that either party is unable to look after {this.state.petName} either in their sole care or
            jointly with the other, that party must advise the other party as soon as practicable and agree whether
            either:
          </p>
          <ol>
            <li>The sole care will pass to the other; or</li>
            <li>
              The sole care will pass to another other than {this.state.originator} or {this.state.partner} who will
              continue to adhere to the care of {this.state.petName}; or
            </li>
            <li>
              {this.state.originator} and {this.state.partner} will take advice from a reputable organization with
              regards to finding a new home for {this.state.petName}, or, where {this.state.petName}
              has a monetary value, sale to be agreed with the pet’s welfare as a priority and the proceeds divided
              either equally or as set out above; or
            </li>
            <li>
              That {this.state.originator} or {this.state.partner} will buy out the share of the other.
            </li>
          </ol>
          <p>
            In the event that the parties agree either to sell {this.state.petName} to the other party or to a third
            party, the parties shall have fourteen days to agree to the value of the pet between themselves, or, if
            unable to agree on a price, the value shall be ascertained, in the event that {this.state.petName} is
            pedigreed, by {this.state.petName}
            ’s breeder. If neither party wishes to buy the other’s interest or care for the pet, they should seek advice
            from a reputable charity or pet care organization to find a new home for {this.state.petName}.
          </p>
        </div>
        <div className="section">
          <h2>Termination of Agreement</h2>
          <p>
            This agreement will terminate either by agreement of the parties or upon the parties adopting a divorce or
            dissolution of civil partnership, in the event that such decree explicitly addresses the ownership and care
            of {this.state.petName}.
          </p>
        </div>
        <div className="section">
          <h2>Other Provisions</h2>
          <p>The parties agree that:</p>
          <ol>
            <li>This agreement is specifically enforceable and that time is of the essence,</li>
            <li>This agreement may be modified only in writing and when adopted by both parties,</li>
            <li>A court may not recognize this agreement as enforceable and, if that is the case, both</li>
            <li>parties will endeavor to make good-faith efforts to follow the spirit of this agreement,</li>
            <li>They consent to their electronic signature being valid and enforceable, and</li>
            <li>They have read, understand, and agree to the Pet Prenup LLC Terms of Service.</li>
          </ol>
        </div>
      </div>
    );
  }
}

export default ContractView;
