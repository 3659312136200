import React, { useState, useEffect } from 'react';
import Network from '../services/network-requests';
import { Table, Button, Divider, Popconfirm } from 'antd';
import AdminForm from './form';
import Styles from '../admin-global.module.css';
import openNotificationWithIcon from '../../utilities/notification';

export default function Admins(props) {
  const [data, setAdmins] = useState({ data: [] });
  const [visible, setDrawer] = useState(false);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    fetchData();
  }, [admin]);

  const fetchData = async () => {
    const result = await Network.get('/admins');
    setAdmins(result.data);
  };

  const drawerAction = () => {
    setDrawer(!visible);
  };

  const closeDrawerAndRefresh = admin => {
    setDrawer(false);
    setAdmin(admin);
  };

  const setUserAndOpenDrawer = a => {
    setAdmin(a);
    setDrawer(true);
  };

  const cancel = () => {};

  const confirm = async admin => {
    const result = await Network.delete('/admins', admin._id);
    networkResponse(result, 'Admin removed');
  };

  const networkResponse = (res, message) => {
    if (res && res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', res.data.error);
      } else if (res.status === 201 || res.status === 200) {
        openNotificationWithIcon('success', 'Success', message);
        fetchData();
      }
    }
  };

  const resetEmail = async record => {
    const result = await Network.post('/admin/reset-password', { email: record.email });
    networkResponse(result, 'Password reset email sent');
  };

  const buildColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, obj) => (
          <span>
            {obj.firstName} {obj.lastName}
          </span>
        ),
      },
      {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a href="#" onClick={() => setUserAndOpenDrawer(record)}>
              Edit {record.name}
            </a>
            <Divider type="vertical" />
            <Popconfirm
              placement="topRight"
              title="Are you sure?"
              onConfirm={() => confirm(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Delete</a>
            </Popconfirm>
            <Divider type="vertical" />
            <a href="#" onClick={() => resetEmail(record)}>
              Password Reset Email
            </a>
          </span>
        ),
      },
    ];
  };

  return (
    <div>
      <Button id={Styles.addBtn} type="primary" icon="plus" onClick={() => drawerAction()}>
        New Admin
      </Button>
      <AdminForm
        drawerAction={drawerAction}
        visible={visible}
        closeDrawerAndRefresh={closeDrawerAndRefresh}
        admin={admin}
      />
      <div className={Styles.clearBoth} />
      <Table rowKey={record => record._id} dataSource={data.admins} columns={buildColumns()} />
    </div>
  );
}
