import React, { useState, useEffect } from 'react';
import { List, Icon, Popconfirm, Drawer } from 'antd';
import Network from '../services/network-requests';
import openNotificationWithIcon from '../../utilities/notification';
import NoteForm from './note-form';
import moment from 'moment';
import './notes-list.css';

const NotesList = function(props) {
  // const user = props.user;
  const [visible, setDrawer] = useState(false);
  const [user, setUser] = useState();
  const [note, setNote] = useState(null);
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);
  const cancel = () => {};

  const confirm = async admin => {
    const result = await Network.delete('/admin-notes', admin._id);
    networkResponse(result, 'Note removed');
  };

  const networkResponse = (res, message) => {
    if (res && res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', res.data.error);
      } else if (res.status === 201 || res.status === 200) {
        openNotificationWithIcon('success', 'Success', message);
        props.fetchData();
        setDrawer(false);
      }
    }
  };

  const drawerAction = title => {
    setDrawer(!visible);
    if (visible === false) {
      setNote(null);
    }
  };

  const notesHeader = () => {
    return (
      <div>
        Notes
        <a href="#" onClick={() => drawerAction('')} style={{ float: 'right' }}>
          <Icon type="plus" />
        </a>
      </div>
    );
  };

  const editNote = n => {
    setNote(n);
    setDrawer(true);
  };

  const list = () => {
    if (user && user.notes) {
      // reverse notes
      var notes = user.notes.map(user.notes.pop, [...user.notes]);
      return (
        <List
          header={notesHeader()}
          style={{ backgroundColor: 'white' }}
          bordered
          dataSource={notes}
          renderItem={note => (
            <List.Item
              actions={[
                <a href="#" onClick={() => editNote(note)}>
                  <Icon type="edit" />
                </a>,
                <Popconfirm
                  placement="topRight"
                  title="Are you sure?"
                  onConfirm={() => confirm(note)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <a href="#">Delete</a>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta description={note.note} />
              Admin: {note.recordedBy}
              <br />
              {note ? moment(note.created).format('MM/DD/YYYY hh:mma') : ''}
            </List.Item>
          )}
        />
      );
    }
  };

  return (
    <div>
      <Drawer
        title={note === null ? 'New Note' : 'Edit Note'}
        placement="right"
        closable={true}
        onClose={() => drawerAction('')}
        visible={visible}
        width="60%"
      >
        {user ? <NoteForm id={user._id} fetchData={props.fetchData} drawerAction={drawerAction} note={note} /> : ''}
      </Drawer>
      {list()}
    </div>
  );
};

export default NotesList;
