import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './contracts.css';
import DocPen from '../assets/doc-pen.png';
import DocCheck from '../assets/doc-check.png';
import DocClock from '../assets/doc-clock.png';
import DocDotted from '../assets/doc-dotted.png';

class Contract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: this.props.contracts || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contracts !== this.props.contracts) {
      this.setState({ contracts: this.props.contracts });
    }
  }

  statusImage = c => {
    if (c.statusCode === 1 || c.statusCode === 2 || c.statusCode === 3) {
      return <img className="contract-image" alt="contract imag" src={DocClock} />;
    }
    if (c.statusCode === 4) {
      return <img className="contract-image" alt="contract imag" src={DocPen} />;
    }
    if (c.statusCode === 5) {
      return <img className="contract-image" alt="contract imag" src={DocCheck} />;
    }
  };

  contractDate = date => {
    var d = date.split('T');
    var str = d[0].split('-');
    var fin = `${str[1]}/${str[2]}/${str[0]}`;
    return <div className="contract-date">{fin}</div>;
  };

  contractBadge = contract => {
    if (contract.statusCode === 1 || contract.statusCode === 3) {
      return <span className={`contract-status s${contract.statusCode}`}>{contract.status}</span>;
    }
    if (contract.statusCode < 5) {
      return <span className={`contract-status s${contract.statusCode}`}>{contract.status}</span>;
    }

    return <span className={`contract-status s${contract.statusCode}`}>{contract.status}</span>;
  };

  renderContracts = () => {
    if (this.state.contracts.length) {
      var contracts = this.state.contracts;
      var contractTable = contracts.map(contract => {
        return (
          <div key={contract._id}>
            <Link to={contract.statusCode < 5 ? `/contracts/${contract._id}` : `/contracts/review/${contract._id}`}>
              <div className="flex contract-item" key={contract._id}>
                <div className="flex">
                  <div className="status-image">{this.statusImage(contract)}</div>
                  <div className="contract-parties-names">
                    <div className="contract-pet-name">{contract.petName}</div>
                    <div className="owner-names">
                      {contract.firstName} {contract.lastName} and {contract.partnerFirstName}{' '}
                      {contract.partnerLastName}
                    </div>
                  </div>
                </div>
                <div className="flex date-status">
                  {this.contractDate(contract.updated)}
                  {this.contractBadge(contract)}
                </div>
              </div>
            </Link>
          </div>
        );
      });
      return <div>{contractTable}</div>;
    } else {
      return (
        <div className="no-contracts">
          <img alt="contract outline" src={DocDotted} />
          <div className="intro">You have no contracts</div>
          <p>Create one today!</p>
        </div>
      );
    }
  };

  render() {
    return (
      <div id="contracts">
        <h1>Your Contracts</h1>
        <div className="divider" />
        {this.renderContracts()}
      </div>
    );
  }
}
export default Contract;
