import React, { Component } from 'react';
import { Modal } from 'antd';
import './modal.css';
class PPModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    return (
      <Modal
        className="pp-modal"
        width="620px"
        visible={this.props.modalVisible}
        onCancel={this.props.hideModal}
        footer={[
          <a href="#" class="link" onClick={this.props.primaryButtonAction}>
            Return To Home
          </a>,
        ]}
      >
        <div className="modal-container">{this.props.children}</div>
      </Modal>
    );
  }
}
export default PPModal;
