import React from 'react';
import Network from '../services/network-requests';
import openNotificationWithIcon from '../../utilities/notification';

const SendPassword = function(props) {
  const sendPassword = async () => {
    const user = { email: props.email };
    var res = await Network.post('/reset-password', user);

    if (res.status === 404) {
      openNotificationWithIcon('error', 'Error', res.data.error);
    } else if (res.status === 201 || res.status === 200) {
      openNotificationWithIcon('success', 'Success', 'Email sent.');
    }
  };

  return (
    <a href="#" onClick={() => sendPassword()}>
      Send it!
    </a>
  );
};

export default SendPassword;
