import React, { useState, useEffect } from 'react';
import Network from '../services/network-requests';
import { Skeleton, Modal, Button } from 'antd';
import ContractView from '.././../contracts/contract-view';
import './admin-users.css';

const SendPassword = function(props) {
  const [contracts, setContracts] = useState([]);
  const [visible, setVisible] = useState(false);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    if (props.user._id) {
      fetchData();
    }
  }, [props.user]);

  useEffect(() => {
    if (contract !== null) {
      setVisible(true);
    }
  }, [contract]);

  useEffect(() => {
    if (!visible) {
      setContract(null);
    }
  }, [visible]);

  const fetchData = async () => {
    const result = await Network.get('/admin-contracts', props.user._id);

    setContracts(result.data.contracts);
  };

  const viewContract = contract => {
    setContract(contract);
  };

  const contractList = () => {
    if (contracts.length > 0) {
      return contracts.map(c => (
        <div key={c._id}>
          <a href="javascritp:;" onClick={() => viewContract(c)}>
            View Contract
          </a>
        </div>
      ));
    } else {
      return 'No contracts created.';
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        className="admin-modal"
        style={{ top: 20 }}
        width="90%"
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <ContractView contract={contract} />
      </Modal>
      {contracts === null ? <Skeleton /> : contractList()}
    </div>
  );
};

export default SendPassword;
