import React, { Component } from 'react';
import { Form, Button, Input } from 'antd';
import './contact.css';
import Network from '../services/network';
import openNotificationWithIcon from '../utilities/notification';
const TextArea = Input.TextArea;
const FormItem = Form.Item;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  // submit
  handleSubmit = e => {
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let message = {
          name: values.name,
          email: values.email,
          message: values.message,
        };
        this.loadingTrue();
        Network.post('/contact', message, this.networkResponse);
      } else {
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', 'Error sending message. Please try again.');
      } else if (res.status === 201 || res.status === 200) {
        this.props.form.resetFields();
        openNotificationWithIcon('success', 'Success', 'Message has been sent.');
      }
    }
  };

  // loading indicators
  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="contact">
        <Form onSubmit={this.handleSubmit} id="contact-form">
          <h1>Contact</h1>
          <FormItem label="Name">
            {getFieldDecorator('name', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{ required: true, message: 'Please enter your name.' }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator('email', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{ required: true, message: 'Please enter your email.' }],
            })(<Input />)}
          </FormItem>
          <div className="form-url">
            <FormItem label="url">{getFieldDecorator('Url', {})(<Input />)}</FormItem>
          </div>
          <FormItem label="Message">
            {getFieldDecorator('message', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{ required: true, message: 'Please enter a message.' }],
            })(<TextArea placeholder="Message..." />)}
          </FormItem>

          <FormItem>
            <Button className="link darker sign-btn" loading={this.state.loading} htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}
export default Form.create()(ContactForm);
