import React from 'react';
const contractHeader = function(props) {
  if (props.statusCode === 5) {
    return (
      <div style={{ paddingTop: '20px' }} className="center header">
        <div className="page-title">
          <span role="img" aria-label="party">
            🎉
          </span>
          <span> Contract signed </span>
          <span role="img" aria-label="party">
            🎉
          </span>
        </div>
        <div className="page-content">You’re all done, this contact is now legally binding. Great Job!</div>
      </div>
    );
  } else {
    return (
      <div className="center header">
        <div className="page-title review-contract">Review Contract</div>
        <div className="page-content">
          Take a look over the contract and make sure everything is correct.
          <br />
          Remember, this is a legally binding agreement between you and your partner.
        </div>
      </div>
    );
  }
};

export default contractHeader;
