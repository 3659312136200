import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './ss_standard.css';
import LayoutChooser from './LayoutChooser';

ReactDOM.render(
  <BrowserRouter>
    <LayoutChooser />
  </BrowserRouter>,
  document.getElementById('root')
);
