import React, { Component } from 'react';
import Logo from '../assets/plans_logo@3x.png';
import Plan from './plan';
import PRICES from '../constants';

class Plans extends Component {
  componentDidMount() {
    document.body.classList.add('plans');
  }

  componentWillUnmount() {
    document.body.classList.remove('plans');
  }
  render() {
    return (
      <div className="container">
        <div id="plans-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div id="pricing">
          <span id="plans" />
          <h2 className="header-text">Select your plan</h2>
          <div style={{ marginTop: '5px' }}>Choose from our flexible options</div>
          <div className="row" id="current-plans">
            <Plan plan={PRICES.STARTER.planName} price={PRICES.STARTER.amount} color="blue">
              <div className="feature">
                <div className="title">{PRICES.STARTER.description}</div>
                <p>
                  This option is purrfect for a family with one pet. Make sure your furry friend has the right coverage
                  they need.
                </p>
              </div>
            </Plan>

            <Plan plan={PRICES.DELUXE.planName} price={PRICES.DELUXE.amount} color="pink" class="middle">
              <div className="feature">
                <div className="title">{PRICES.DELUXE.description}</div>
                <p>
                  This option is for the couple or family with up to three fur babies. Do the right thing for all your
                  pets.
                </p>
              </div>
            </Plan>
            <Plan plan={PRICES.UNLIMITED.planName} price={PRICES.UNLIMITED.amount} color="peach">
              <div className="feature">
                <div className="title">{PRICES.UNLIMITED.description}</div>
                <p>
                  All paws, claws, and feathers are safe here. With this option, each pet in your family is covered, no
                  matter the number.
                </p>
              </div>
            </Plan>
          </div>
        </div>
      </div>
    );
  }
}
export default Plans;
