import React, { Component } from 'react';
import Admin from './Admin';
import App from './App';
import { StripeProvider, Elements } from 'react-stripe-elements';

class LayoutChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
    };
  }

  componentDidMount() {
    var str = window.location.href;
    var splitUrl = str.split('/');
    if (splitUrl[3] && splitUrl[3] === 'admin') {
      this.setState({ isAdmin: true });
    }
  }

  render() {
    return this.state.isAdmin ? (
      <Admin />
    ) : (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
        <Elements>
          <App />
        </Elements>
      </StripeProvider>
    );
  }
}
export default LayoutChooser;
