import React, { Component } from 'react';
import './testimony.css';

const testimonials = [
  {
    involved: 'Gregg, Kate, and Ralph',
    testimony: 'We loved using this service, it’s great to have things like this all figured out and not to worry!',
  },
  {
    involved: 'Mark, Shana, and Tucker',
    testimony: "Tucker is a huge part of both of our lives, we're glad to be able to feel secure.",
  },
  {
    involved: 'Jim Bob, Jolene,  Scruffy and Streak',
    testimony:
      "Our dogs are just as important to us as anything else in our relationship, we're glad we can have peace of mind for whatever happens in the future.",
  },
];
var timeout = null;
var timeout1 = null;
class Testimonials extends Component {
  componentDidMount = () => {
    var wrappers = document.getElementsByClassName('dog-profile');
    wrappers[0].classList.add('show');
    let i = 0;
    timeout = setInterval(function() {
      if (wrappers[i]) {
        wrappers[i].classList.remove('show');
      }
      if (i === testimonials.length - 1) {
        i = 0;
      } else {
        i += 1;
      }
      if (wrappers[i]) {
        timeout1 = setTimeout(() => {
          wrappers[i].classList.add('show');
        }, 50);
      }
    }, 5000);
  };

  componentWillUnmount = () => {
    clearTimeout(timeout);
    clearTimeout(timeout1);
  };

  renderTestimonials = () => {
    return testimonials.map((t, i) => {
      return (
        <div key={i} id="dog-profile" className={`dog-profile dog-${i}`}>
          <div className="t-wrapper">
            <div className="involved">{testimonials[i].involved}</div>
            <div className="testimony">{testimonials[i].testimony}</div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <div id="testimonials">
        <div id="trans" />
        {this.renderTestimonials()}
      </div>
    );
  }
}
export default Testimonials;
