import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Contracts from '../contracts';
import Network from '../services/network';
import './dashboard.css';
import { Link } from 'react-router-dom';
import Upgrade from '../upgrade';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      originator: true,
      visible: false,
    };
  }
  componentDidMount() {
    document.body.classList.add('dashboard');
    this.fetchContracts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.contract !== prevProps.contracts) {
      this.fetchContracts();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('dashboard');
  }

  /**
   * contract stuff
   */
  fetchContracts = () => {
    Network.get('/contracts', this.setContracts);
  };

  setContracts = res => {
    if (res) {
      if (res.status === 401) {
      } else if (res.status === 201 || res.status === 200) {
        let contracts = res.data.contracts;
        let originator = res.data.originator;
        let plan = res.data.plan;
        let currentContractsCountPerPlan = res.data.currentContractsCountPerPlan;
        this.setState({ contracts, originator, plan, currentContractsCountPerPlan });
      }
    }
  };

  // reloadContracts
  reloadContracts = () => {
    this.setState({ contracts: [], visible: false }, () => {
      this.fetchContracts();
    });
  };

  renderNewButton = () => {
    if (this.state.contracts.length === 0) {
      return this.createNewContractButton();
    }
    if (this.state.currentContractsCountPerPlan < 1 && this.state.plan === 'starter') {
      return this.createNewContractButton();
    }
    if (this.state.currentContractsCountPerPlan < 3 && this.state.plan === 'deluxe') {
      return this.createNewContractButton();
    }
    if (this.state.plan === 'unlimited') {
      return this.createNewContractButton();
    }
    // this.props.reloadContracts will go to the dashboard and reload contracts
    return this.upgradeAccountButton();
  };

  createNewContractButton = () => {
    return (
      <Link
        to="/contracts/new"
        className={`link aqua new-contract-link ${this.state.contracts.length === 0 ? 'zero' : ''}`}
      >
        {this.state.contracts.count}
        New Contract
      </Link>
    );
  };

  upgradeAccountButton = () => {
    return (
      <a href="#" className="link aqua new-contract-link" onClick={this.openModal}>
        New Contract
      </a>
    );
  };

  openModal = e => {
    e.preventDefault();
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    if (!this.state.originator && this.state.partnerContracts.length) {
      let id = this.state.partnerContracts[0]._id;
      let url = `/contracts/review/${id}`;
      return <Redirect to={url} />;
    }
    return (
      <div id="dashboard">
        <Contracts contracts={this.state.contracts} plan={this.state.plan} reloadContracts={this.reloadContracts} />
        {this.renderNewButton()}
        <Upgrade
          plan={this.state.plan}
          reloadContracts={this.reloadContracts}
          visible={this.state.visible}
          handleCancel={this.handleCancel}
        />
      </div>
    );
  }
}
export default Dashboard;
