import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Clickety from '../clickety/clickety_post';
import logo from '../assets/logo.png';
import './header.css';

class Header extends Component {
  renderProfileOrDashboard = () => {
    if (window.location.pathname === '/dashboard') {
      return <Link to="/profile">Settings</Link>;
    } else {
      return <Link to="/dashboard">Dashboard</Link>;
    }
  };

  renderHeader = () => {
    if (this.props.isAuthenticated) {
      return (
        <div id="header">
          <div className="container">
            <Link to="/dashboard">
              <img id="private-logo" src={logo} alt="logo" />
            </Link>
            <div className="admin-nav-link-container">
              {this.renderProfileOrDashboard()}
              <Link to="/" onClick={() => this.props.signOut()} id="sign-out-btn">
                Sign Out
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="header-public">
          <div className="container">
            <Link to="/">
              <img id="public-logo" src={logo} alt="logo" />
            </Link>
            <div id="h-links">
              <Link
                to="/plans"
                className="link plans"
                onClick={() => Clickety.recordGoal(process.env.REACT_APP_VIEW_PLANS)}
              >
                Get Started
              </Link>
              <Link to="/sign-in" className="link sign-in-link">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return this.renderHeader();
  }
}
export default Header;
