import React, { Component } from 'react';
import { Form, Input, Row, Col, Radio, Button, Alert } from 'antd';
import { Redirect } from 'react-router-dom';
import GreenCheck from '../assets/green-check.png';
import noGo from '../assets/no.png';
import Network from '../services/network';
import moment from 'moment';
import ImageUpload from './image-upload';
import logo from '../assets/logo3x.png';
import ContractFooter from './contract-footer';
import checkDate from '../utilities/validateDate';
import openNotificationWithIcon from '../utilities/notification';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class ContractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageUrl: null,
      originator: 'You',
      partner: 'Partner',
      petName: 'your pet',
      originatorDays: 0,
      partnerDays: 0,
      customCustody: '',
      customCare: '',
      customCompliance: '',
      customHolidays: '',
      customOtherNeeds: '',
      isEditing: false,
      loading: false,
      successSaving: false,
      errorSaving: false,
      contract: null,
      saved: false,
      prog: 0,
      height: 0,
      sectionValid: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
      },
      toDashboard: false,
      deleteFiles: [],
      windowHeight: 0,
      mobileProg: 0,
      formIsValid: false,
      dobFocus: false,
    };
  }

  componentWillMount() {
    document.body.classList.add('no-head-foot');
    document.body.classList.add('new');
    this.fetchUser();
    document.addEventListener('keyup', this.pressEnter, false);
    document.addEventListener('keydown', this.keyPressed, false);
    document.addEventListener('scroll', this.scrollStopped, false);
  }

  scrollStopped = e => {
    window.clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(() => {
      var prog = 0;
      var ids = ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q8', 'q9', 'q10'];
      for (var i = 0; i < ids.length; i++) {
        var e = document.getElementById(ids[i]);
        if (e) {
          if (e.getBoundingClientRect().top <= 0) {
            prog += 1;
          }
        }
      }
      if (prog !== this.state.mobileProg && prog < 11) {
        this.setState({ mobileProg: prog });
      }
    }, 50);

    var d = document.getElementById('q10').getBoundingClientRect();
    if (d.top <= 0) {
      this.setState({ mobileProg: 10 });
    }
  };

  fetchUser = () => {
    Network.get('/profile', this.setUpUser);
  };

  setUpUser = res => {
    if (res && res.status === 200) {
      var user = res.data.user;
      this.props.form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
      });

      if (user.dateOfBirth !== null && user.dateOfBirth !== undefined) {
        this.props.form.setFieldsValue({
          dateOfBirth: this.formatDate(user.dateOfBirth),
        });
      }
    }
  };

  currentWindow = () => {
    var header = document.getElementById('form-header');
    var footer = document.getElementById('c-footer');
    var headerAndFooter = header.getBoundingClientRect().height + footer.getBoundingClientRect().height;
    return window.innerHeight - headerAndFooter;
  };

  componentDidMount() {
    this.onOrientationChange();
    var header = document.getElementById('form-header');
    var footer = document.getElementById('c-footer');
    var headerAndFooter = header.getBoundingClientRect().height + footer.getBoundingClientRect().height;
    var windowHeight = window.innerHeight - headerAndFooter;
    this.setState({ windowHeight: this.currentWindow() });
    var x = this.getQuestionElements();
    var form = document.getElementById('form-contract');
    form.style.height = window.innerHeight + 'px';
    for (var i = 0; i < x.length; i++) {
      var theHeight = x[i].getBoundingClientRect().height;
      if (window.screen.width >= 768) {
        x[i].style.paddingTop = (windowHeight - theHeight) / 2 + 'px';
      }
    }

    window.scrollTo(0, 0);

    window.onscroll = ev => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.validateForm();
      }
    };
  }

  onOrientationChange = () => {
    window.addEventListener('orientationchange', () => {
      window.setTimeout(() => {
        // var readout = document.getElementById('readout');
        // readout.innerText = `width ${window.innerWidth} height: ${window.innerHeight}`;
        this.resetQuestionPositions();
        this.resetTopMargin();
      }, 500);
    });
  };

  resetQuestionPositions = () => {
    var form = document.getElementById('form-contract');
    form.style.height = window.innerHeight + 'px';
    var x = this.getQuestionElements();
    for (var i = 0; i < x.length; i++) {
      var theHeight = x[i].getBoundingClientRect().height;
      x[i].style.paddingTop = (window.innerHeight - theHeight) / 2 + 'px';
    }
  };

  // reset top margin on orientation change
  resetTopMargin = () => {
    var x = this.getQuestionElements();
    var containerQuestionTopMargin = 0;
    for (var i = 0; i < x.length; i++) {
      var theHeight = x[i].getBoundingClientRect().height;
      if (this.state.prog > i) {
        containerQuestionTopMargin = containerQuestionTopMargin + theHeight;
      }
    }

    this.setState({ height: containerQuestionTopMargin });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.prog !== this.state.prog) {
      if (window.screen.width >= 768) {
        this.updateOpacity(prevState.prog, this.state.prog);
        this.updatePosition(prevState.prog, this.state.prog);
      }
      this.updateLabels();
    }

    if (prevState.mobileProg !== this.state.mobileProg) {
      this.updateLabels();
    }

    if (this.props.contract !== prevProps.contract) {
      let c = this.props.contract;
      var dob = c.userId.dateOfBirth !== undefined ? this.formatDate(c.userId.dateOfBirth) : null;
      this.setState({ isEditing: true, contract: c, currentImageUrl: c.image });
      this.props.form.setFieldsValue({
        petName: c.petName,
        species: c.species,
        breed: c.breed,
        chipId: c.chipId,
        uniqueMarkings: c.uniqueMarkings,
        firstName: c.firstName,
        lastName: c.lastName,
        dateOfBirth: dob,
        partnerFirstName: c.partnerFirstName,
        partnerLastName: c.partnerLastName,
        partnerEmail: c.partnerEmail,
        primaryGuardian: c.primaryGuardian,
        becameMember: c.becameMember,
        custody: c.custody,
        care: c.care,
        otherNeeds: c.otherNeeds,
        validSection: -1,
      });
    }

    // validate section one, feature creep after designer review
    const { getFieldsValue } = this.props.form;
    // get form field values
    var sectionOne = getFieldsValue(['petName', 'species', 'breed']);
    var sectionTwo = getFieldsValue(['firstName', 'lastName', 'dateOfBirth']);
    if (sectionTwo.dateOfBirth !== undefined) {
      var check = checkDate(sectionTwo.dateOfBirth);
      if (check.error) {
        sectionTwo.dateOfBirth = '';
      }
    }
    var sectionThree = getFieldsValue(['partnerFirstName', 'partnerLastName', 'partnerEmail']);
    if (sectionThree.partnerEmail !== undefined) {
      var validEmail = this.validateEmail(sectionThree.partnerEmail);
      if (!validEmail) {
        sectionThree.partnerEmail = '';
      }
    }
    var sectionFour = getFieldsValue(['primaryGuardian']);
    var sectionFive = getFieldsValue(['becameMember']);
    var sectionSix = getFieldsValue(['custody']);
    var sectionSeven = getFieldsValue(['care']);
    var sectionEight = getFieldsValue(['otherNeeds']);
    var sectionNine = getFieldsValue(['holidays']);
    var sectionTen = getFieldsValue(['compliance']);
    /// check form fields values
    // sectionOne.currentImageUrl = this.state.currentImageUrl || '';
    var arrayOne = Object.values(sectionOne);
    var arrayTwo = Object.values(sectionTwo);
    var arrayThree = Object.values(sectionThree);
    var arrayFour = Object.values(sectionFour);
    var arrayFive = Object.values(sectionFive);
    var arraySix = Object.values(sectionSix);
    var arraySeven = Object.values(sectionSeven);
    var arrayEight = Object.values(sectionEight);
    var arrayNine = Object.values(sectionNine);
    var arrayTen = Object.values(sectionTen);
    var sectionValid = {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
    };
    var sections = [
      arrayOne,
      arrayTwo,
      arrayThree,
      arrayFour,
      arrayFive,
      arraySix,
      arraySeven,
      arrayEight,
      arrayNine,
      arrayTen,
    ];
    sections.forEach((section, idx) => {
      if (idx === this.state.prog) {
        sectionValid[idx] = !section.includes(undefined) && !section.includes('');
      }
    });
    var s = this.state.prog;
    if (this.state.sectionValid[s] !== sectionValid[s]) {
      this.setState({ sectionValid });
    }
  }

  /**
   * email checker
   */

  validateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  /**
   * date formatter
   */
  formatDate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    document.body.classList.remove('no-head-foot');
    document.body.classList.remove('new');
    document.removeEventListener('keyup', this.pressEnter, false);
    document.removeEventListener('keydown', this.keyPressed, false);
    document.removeEventListener('scroll', this.scrollStopped, false);
  }

  checkSectionOnTabbing = e => {
    if (this.state.sectionValid[this.state.prog]) {
      this.updateProgression();
    } else {
      e.preventDefault();
    }
  };

  keyPressed = e => {
    if (!e.path) {
      return;
    }
    if (e.path[0].tagName === 'BODY') {
      e.preventDefault();
      return;
    }
    if (e.keyCode === 9 && this.state.prog < 10) {
      if (e.path[0].id === 'image-tab') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[0].id === 'dateOfBirth') {
        var bd = document.getElementById('dateOfBirth');
        var err = checkDate(bd.value);
        if (!err.error) {
          this.checkSectionOnTabbing(e);
        } else {
          e.preventDefault();
        }
      }

      if (e.target.id === 'partnerEmail') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'primaryGuardian') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'becameMember') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'custody') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'care') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'otherNeeds') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'holidays') {
        this.checkSectionOnTabbing(e);
      }

      if (e.path[3].id === 'compliance') {
        this.checkSectionOnTabbing(e);
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let contract = {
          petName: values.petName,
          species: values.species,
          breed: values.breed,
          chipId: values.chipId,
          uniqueMarkings: values.uniqueMarkings,
          firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
          dateOfBirth: moment(values.dateOfBirth).toDate(),
          partnerFirstName: values.partnerFirstName,
          partnerLastName: values.partnerLastName,
          partnerEmail: values.partnerEmail,
          primaryGuardian: values.primaryGuardian,
          becameMember: values.becameMember,
          custody: values.custody,
          customCustody: this.state.customCustody,
          originatorDays: this.state.originatorDays,
          partnerDays: this.state.partnerDays,
          care: values.care,
          customCare: this.state.customCare,
          holidays: values.holidays,
          customHolidays: this.state.customHolidays,
          compliance: values.compliance,
          customCompliance: this.state.customCompliance,
          otherNeeds: values.otherNeeds,
          customOtherNeeds: this.state.customOtherNeeds,
          image: this.state.currentImageUrl,
        };
        Network.post('/contracts', contract, this.networkResponse);
      } else {
        this.setState({ errorSaving: true });
        setTimeout(() => {
          this.setState({ errorSaving: false });
        }, 3000);
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res.status) {
      if (res.status === 404) {
        this.setState({ errorSaving: true });
      } else if (res.status === 201 || res.status === 200) {
        this.setState({ successSaving: true, saved: true, contract: res.data.contract });
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };

  loadingFalse = () => {
    this.setState({ loading: false });
  };

  handelOriginatorDays = e => {
    this.setState({ originatorDays: e.target.value });
  };

  handelPartnerDays = e => {
    this.setState({ partnerDays: e.target.value });
  };

  handelCustomCustody = e => {
    this.props.form.setFieldsValue({
      custody: e.target.value,
    });
    this.setState({ customCustody: e.target.value });
  };

  handelCustomCare = e => {
    this.props.form.setFieldsValue({
      care: e.target.value,
    });
    this.setState({ customCare: e.target.value });
  };

  handelCustomHolidays = e => {
    this.props.form.setFieldsValue({
      holidays: e.target.value,
    });
    this.setState({ customHolidays: e.target.value });
  };

  handelCustomCompliance = e => {
    this.props.form.setFieldsValue({
      compliance: e.target.value,
    });
    this.setState({ customCompliance: e.target.value });
  };

  handelCustomOtherNeeds = e => {
    this.props.form.setFieldsValue({
      otherNeeds: e.target.value,
    });
    this.setState({ customOtherNeeds: e.target.value });
  };

  setCurrentImageUrl = currentImageUrl => {
    this.setState({ currentImageUrl: currentImageUrl });
  };

  pressEnter = e => {
    if (e.key === 'Enter') {
      // well set b-days valid and check them
      var youBirthdayValid = true;
      this.props.form.validateFields(err => {
        var birthdays = this.props.form.getFieldsValue(['dateOfBirth']);

        if (birthdays.dateOfBirth !== undefined) {
          const dateOfBirth = checkDate(birthdays.dateOfBirth);
          if (dateOfBirth.error) {
            youBirthdayValid = false;
            this.props.form.setFields({
              dateOfBirth: {
                value: '',
                errors: [new Error('Please fill out your birthday.')],
              },
            });
          }
        }
      });

      if (this.state.sectionValid[this.state.prog] === true && youBirthdayValid) {
        this.updateProgression();
      }
    }
  };

  getQuestionElements = () => {
    return document.getElementsByClassName('question');
  };

  updatePosition = (prev, next) => {
    let x = this.getQuestionElements();
    let newHeight = this.state.height;
    if (prev > next) {
      // were going up
      let t = x[next].getBoundingClientRect().height;
      newHeight -= t;
    } else {
      let t = x[prev].getBoundingClientRect().height;
      newHeight += t;
    }
    this.setState({ height: newHeight });
  };

  updateOpacity = (prev, next) => {
    var x = this.getQuestionElements();
    if (prev > next) {
      // were going up
      x[prev].classList.remove('current');
      x[next].classList.remove('dimmed');
      x[next].classList.add('current');
    } else {
      x[prev].classList.add('dimmed');
      x[prev].classList.remove('current');
      x[next].classList.add('current');
    }
  };

  renderContinueButton = () => {
    return (
      <div className={`continue-btn ${this.state.sectionValid[this.state.prog] === true ? 'show' : ''}`}>
        <Button className="link darker sign-btn section-continue" onClick={() => this.updateProgression()}>
          Continue
        </Button>
        <span className="press">or press Enter</span>
      </div>
    );
  };

  /**
   * Update labels
   */
  updateLabels = () => {
    this.updatePetLabel();
    this.updateYourLabel();
    this.updatePartnerLabel();
  };

  updatePetLabel = () => {
    const { getFieldValue } = this.props.form;
    // update pet name
    const petName = getFieldValue('petName');
    if (petName !== undefined) {
      this.setState({ petName: petName });
    }
  };

  updateYourLabel = () => {
    const { getFieldsValue } = this.props.form;
    // update originator
    const originator = getFieldsValue(['firstName', 'lastName']);
    if (originator.firstName && originator.lastName) {
      this.setState({ originator: originator.firstName + ' ' + originator.lastName });
    } else {
      this.setState({ originator: 'you' });
    }
  };

  updatePartnerLabel = () => {
    const { getFieldsValue } = this.props.form;
    // update partner
    const partner = getFieldsValue(['partnerFirstName', 'partnerLastName']);
    if (partner.partnerFirstName && partner.partnerLastName) {
      this.setState({ partner: partner.partnerFirstName + ' ' + partner.partnerLastName });
    } else {
      this.setState({ partner: 'partner' });
    }
  };

  /**
   * update progressions
   */
  updateProgression = () => {
    var prog = this.state.prog;
    this.setState({ prog: prog + 1 }, () => {
      this.validateOnLastItem();
    });
  };

  validateOnLastItem = () => {
    if (this.state.prog >= 9) {
      this.validateForm();
    }
  };

  /**
   * callback for footer
   */
  animateForm = progress => {
    this.validateOnLastItem();
    if (progress > this.state.prog) {
      this.props.form.validateFields(err => {
        if (err) {
          if (
            this.state.prog === 0 &&
            !err.petName &&
            !err.species &&
            !err.breed
            // this.state.currentImageUrl !== null
          ) {
            console.log('wtf');
            this.setState({ prog: progress });
          }

          if (this.state.prog === 1 && !err.firstName && !err.lastName && !err.dateOfBirth) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 2 && !err.partnerFirstName && !err.partnerLastName && !err.partnerEmail) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 3 && !err.primaryGuardian) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 4 && !err.becameMember) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 5 && !err.custody) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 6 && !err.care) {
            this.setState({ prog: progress });
          }

          if (this.state.prog === 7 && !err.otherNeeds) {
            this.setState({ prog: progress });
          }
          if (this.state.prog === 8 && !err.holidays) {
            this.setState({ prog: progress });
          }
          if (this.state.prog === 9 && !err.compliance) {
            this.setState({ prog: progress }, () => {
              this.validateForm();
            });
          }
        } else {
          this.setState({ prog: progress });
        }
      });
      // TODO: REMOVE ME!!!
      // this.setState({ prog: progress });
    } else {
      this.setState({ prog: progress });
    }
  };

  confirm = () => {
    this.setState({ toDashboard: true });
  };

  validateDate = e => {
    const value = e.target.value;
    const date = checkDate(value);
    if (date.success) {
      e.target.value = date.success;
      this.props.form.setFieldsValue({
        dateOfBirth: date.success,
      });
    } else {
      this.birthdayNotification();
      e.target.value = '';
    }
  };

  /**
   * Birthday notification
   */
  birthdayNotification = () => {
    openNotificationWithIcon('error', 'Error', 'Incorrect birthday format. Please try again.');
  };

  /**
   * footer based on viewport
   */
  contractFooter = () => {
    if (window.innerWidth < 500) {
      return <ContractFooter prog={this.state.mobileProg} />;
    } else {
      return (
        <ContractFooter
          animateForm={this.animateForm}
          prog={this.state.prog}
          updateProgression={this.updateProgression}
          showContinue={this.state.sectionValid[this.state.prog] === true ? 'show' : ''}
        />
      );
    }
  };

  validateForm = () => {
    if (this.state.mobileProg <= 10 || this.state.prog <= 10) {
      this.props.form.validateFields(err => {
        if (!err) {
          this.setState({ formIsValid: true });
        }
      });
    }
  };

  resultsBasedOnCompletingForm = () => {
    if (this.state.formIsValid) {
      return (
        <div className="text-wrap">
          <div className="finished-text">You’ve answered all the questions!</div>
          <div className="finished-text">Next, we'll review everything and sign.</div>
        </div>
      );
    } else {
      return (
        <div className="text-wrap">
          <div className="finished-text">Incomplete</div>
          <div className="finished-text">Look over your contract and see if you missed something.</div>
        </div>
      );
    }
  };

  cancelForm = () => {
    var response = window.confirm('Are you sure you want to quit? All form information will be lost.');
    if (response) {
      this.confirm();
    }
  };

  dobFocused = () => {
    this.setState({ dobFocus: true });
    setTimeout(() => {
      this.setState({ dobFocus: false });
    }, 2000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.saved === true) {
      return <Redirect to={`/contracts/${this.state.contract._id}`} />;
    }

    if (this.state.toDashboard === true) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div>
        <div id="form-header" onClick={this.cancelForm}>
          <div className="container">
            <img id="private-logo" src={logo} alt="logo" />
            <div className="new-closer-wrap">
              <div className="ss-delete" />
            </div>
          </div>
        </div>
        <div id="form-contract">
          <div style={{ marginTop: -this.state.height + 'px' }} className="new contact-questions container">
            <Form id="fid">
              <div id="q1" className="question slim current">
                <h2>About your pet</h2>
                <p>Give us the details! Tell us about your pet.</p>
                <Row gutter={16}>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('petName', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: "Please enter your pet's name" }],
                      })(<Input placeholder="Pet Name (required)" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('species', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please enter a species.' }],
                      })(<Input placeholder="Dog, cat, bird, etc...(required)" />)}
                    </FormItem>
                  </Col>

                  <Col sm={24}>
                    <FormItem>
                      {getFieldDecorator('breed', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please enter breed(s).' }],
                      })(<Input placeholder="Breed(s). Separate with commas (required)" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24}>
                    <FormItem>{getFieldDecorator('chipId', {})(<Input placeholder="Chip ID number" />)}</FormItem>
                  </Col>
                  <Col sm={24}>
                    <FormItem>
                      {getFieldDecorator('uniqueMarkings', {})(<Input placeholder="Unique markings" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24} md={24}>
                    <FormItem id="image-tab">
                      <ImageUpload currentImageUrl={this.state.currentImageUrl} callback={this.setCurrentImageUrl} />
                    </FormItem>
                  </Col>
                </Row>
                <div className={`current-section ${this.state.prog === 0 ? 'show' : ''}`} />
              </div>
              <div id="q2" className="question  slim">
                <h2>About you</h2>
                <p>We already have some of this information, just look over it to make sure it’s correct.</p>
                <Row gutter={16}>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('firstName', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please enter your first name.' }],
                      })(<Input placeholder="First Name (required)" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('lastName', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please enter your last name.' }],
                      })(<Input placeholder="Last Name (required)" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24} md={12}>
                    <FormItem extra="Example: 02/24/2010">
                      {getFieldDecorator('dateOfBirth', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please fill out your birthday.' }],
                      })(<Input placeholder="Birthday" onBlur={this.validateDate} />)}
                    </FormItem>
                  </Col>
                </Row>
                <div className={`current-section ${this.state.prog === 1 ? 'show' : ''}`} />
              </div>
              <div id="q3" className="question slim">
                <h2>About your partner</h2>
                <p>
                  Contracts require a signature from you and your partner. Let us know who they are and where to send
                  it.
                </p>
                <Row gutter={16}>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('partnerFirstName', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: "Please enter your partner's first name." }],
                      })(<Input placeholder="First Name (required)" />)}
                    </FormItem>
                  </Col>
                  <Col sm={24} md={12}>
                    <FormItem>
                      {getFieldDecorator('partnerLastName', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: "Please enter your partner's last name." }],
                      })(<Input placeholder="Last Name (required)" />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem>
                      {getFieldDecorator('partnerEmail', {
                        validateTrigger: ['onBlur'],
                        rules: [
                          { required: true, message: "Please enter your partner's email." },
                          {
                            type: 'email',
                            message: 'Please enter a valid email.',
                          },
                        ],
                      })(<Input onFocus={this.updatePartnerLabel} placeholder="Email (required)" />)}
                    </FormItem>
                  </Col>
                </Row>
                <div className={`current-section ${this.state.prog === 2 ? 'show' : ''}`} />
              </div>
              <div id="q4" className="question radio slim">
                <h2>
                  Who is {this.state.petName}
                  's guardian?
                </h2>
                <p>The other person will automatically be the secondary guardian.</p>
                <FormItem>
                  {getFieldDecorator('primaryGuardian', {
                    rules: [{ required: true, message: 'Please choose primary.' }],
                  })(
                    <RadioGroup>
                      <Radio value="originator">{this.state.originator}.</Radio>
                      <Radio value="partner">{this.state.partner}.</Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 3 ? 'show' : ''}`} />
              </div>
              <div id="q5" className="question radio slim">
                <h2>How did {this.state.petName} become a member of the family?</h2>
                <p>What's the story here?</p>
                <FormItem>
                  {getFieldDecorator('becameMember', {
                    rules: [{ required: true, message: 'Please choose an option.' }],
                  })(
                    <RadioGroup>
                      <Radio value="We adopted petName together">
                        We adopted <strong>{this.state.petName}</strong> together.
                      </Radio>
                      <Radio value="originator brought petName to the family">
                        <strong>{this.state.originator}</strong> brought <strong>{this.state.petName}</strong> to the
                        family.
                      </Radio>
                      <Radio value="partner brought petName to the family">
                        <strong>{this.state.partner}</strong> brought <strong>{this.state.petName}</strong> to the
                        family.
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 4 ? 'show' : ''}`} />
              </div>
              <div id="q6" className="question radio slim">
                <h2>Things happen in relationships..if they do, who will be responsible for {this.state.petName}?</h2>
                <p>The other person will automatically be the secondary guardian.</p>
                <FormItem>
                  {getFieldDecorator('custody', {
                    rules: [{ required: true, message: 'Please choose custody option.' }],
                  })(
                    <RadioGroup>
                      <Radio value="originator will have complete and total custody of petName">
                        <strong>{this.state.originator}</strong> will have complete and total custody of{' '}
                        <strong>{this.state.petName}</strong>.
                      </Radio>
                      <Radio value="partner will have complete and total custody of petName">
                        <strong>{this.state.partner}</strong> will have complete and total custody of{' '}
                        <strong>{this.state.petName}</strong>.
                      </Radio>
                      <Radio value="petName will alternate equally between originator and partner each and every week">
                        <strong>{this.state.petName}</strong> will alternate equally between{' '}
                        <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> each and
                        every week.
                      </Radio>

                      <Radio value="originator gets petName for originatorDays days and partner for partnerDays days...alternating between the same schedule">
                        <strong>{this.state.originator}</strong> gets <strong>{this.state.petName}</strong> for{' '}
                        <input
                          type="text"
                          value={this.state.originatorDays}
                          onChange={this.handelOriginatorDays}
                          id="originator-days"
                        />{' '}
                        days, and {this.state.partner} for{' '}
                        <input
                          type="text"
                          value={this.state.partnerDays}
                          onChange={this.handelPartnerDays}
                          id="partner-days"
                        />{' '}
                        days,...alternating between the same schedule regularly.
                      </Radio>
                      <Radio value="originator will get full guardianship of petName, and partner will have visitation rights based on agreeable times and dates">
                        <strong>{this.state.originator}</strong> will get full guardianship of{' '}
                        <strong>{this.state.petName}</strong>, and <strong>{this.state.partner}</strong> will have
                        visitation rights based on agreeable times and dates.
                      </Radio>

                      <Radio value="partner will get full guardianship of petName, and originator will have visitation rights based on agreeable times and dates">
                        <strong>{this.state.partner}</strong> will get full guardianship of{' '}
                        <strong>{this.state.petName}</strong>, and <strong>{this.state.originator}</strong> will have
                        visitation rights based on agreeable times and dates.
                      </Radio>

                      <Radio value={this.state.customCustody}>
                        We’d prefer to create our own care/visitation agreement:
                        <br />
                        <textarea
                          value={this.state.customCustody}
                          onChange={this.handelCustomCustody}
                          className="custom-textarea"
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 5 ? 'show' : ''}`} />
              </div>
              <div id="q7" className="question radio slim">
                <h2>Who will pay for everyday expenses?</h2>
                <p>Everyday expenses can be things like food, medicine, cleaning etc.</p>
                <FormItem>
                  {getFieldDecorator('care', {
                    rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                  })(
                    <RadioGroup>
                      <Radio value="originator and partner will split costs.">
                        <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> will split
                        costs.
                      </Radio>
                      <Radio value="Whoever has petName at any given time is solely responsible for petName everyday expenses.">
                        Whoever has <strong>{this.state.petName}</strong> at any given time is solely responsible for{' '}
                        <strong>{this.state.petName}</strong> everyday expenses.
                      </Radio>
                      <Radio value="originator will have complete responsibility for the cost of care, and will reimburse any costs that occur while in partner care.">
                        <strong>{this.state.originator}</strong> will have complete responsibility for the cost of care,
                        and will reimburse any costs that occur while in <strong>{this.state.partner}</strong> care.
                      </Radio>
                      <Radio value="partner will have complete responsibility for the cost of care, and will reimburse any costs that occur while in originator care.">
                        <strong>{this.state.partner}</strong> will have complete responsibility for the cost of care,
                        and will reimburse any costs that occur while in <strong>{this.state.originator}</strong> care.
                      </Radio>
                      <Radio value={this.state.customCare}>
                        We'd prefer to create an alternate care agreement instead.
                        <br />
                        <textarea
                          value={this.state.customCare}
                          onChange={this.handelCustomCare}
                          className="custom-textarea"
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 6 ? 'show' : ''}`} />
              </div>
              <div id="q8" className="question radio slim">
                <h2>Other Needs</h2>
                <p>
                  In the event that other, more expensive needs arise for {this.state.petName}, beyond basic necessities
                  (such as medical care, medication, etc), the parties agree to split costs as follows:
                </p>
                <FormItem>
                  {getFieldDecorator('otherNeeds', {
                    rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                  })(
                    <RadioGroup>
                      <Radio value="originator and partner will split all costs 50%/50%">
                        <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> will split
                        all costs 50%/50%.
                      </Radio>
                      <Radio value="originator will be responsible for all costs">
                        <strong>{this.state.originator}</strong> will be responsible for all costs.
                      </Radio>
                      <Radio value="partner will be responsible for all costs">
                        <strong>{this.state.partner}</strong> will be responsible for all costs.
                      </Radio>
                      <Radio value={this.state.customOtherNeeds}>
                        We’d prefer to create our own arrangement:
                        <br />
                        <textarea
                          value={this.state.customOtherNeeds}
                          onChange={this.handelCustomOtherNeeds}
                          className="custom-textarea"
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 7 ? 'show' : ''}`} />
              </div>
              <div id="q9" className="question radio slim">
                <h2>Holidays</h2>
                <p>The parties agree that {this.state.petName} will be shared on Holidays as follows:</p>
                <FormItem>
                  {getFieldDecorator('holidays', {
                    rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                  })(
                    <RadioGroup>
                      <Radio value="originator and partner will split costs.">
                        All holidays are to be shared equally as <strong>{this.state.originator}</strong> or{' '}
                        <strong>{this.state.partner}</strong>
                        ’s work permits, at times and dates to be agreed between the parties giving at least four weeks’
                        notice in advance.
                      </Radio>
                      <Radio value={this.state.customHolidays}>
                        Other arrangement:
                        <br />
                        <textarea
                          value={this.state.customHolidays}
                          onChange={this.handelCustomHolidays}
                          className="custom-textarea"
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 8 ? 'show' : ''}`} />
              </div>
              <div id="q10" className="question radio slim">
                <h2>Compliance</h2>
                <p>
                  The parties agree that in the event that the matters set out in this agreement are ignored by either
                  party to the extent that the lack of compliance amounts to neglect, the pet will revert absolutely to
                  the other party and the defaulting party will pay all the costs that were agreed to be paid by them in
                  any event for:
                </p>
                <FormItem>
                  {getFieldDecorator('compliance', {
                    rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                  })(
                    <RadioGroup>
                      <Radio value="The duration of the ownership of the pet">
                        The duration of the ownership of the pet.
                      </Radio>
                      <Radio value="By order of any court">By order of any court.</Radio>
                      <Radio value={this.state.customCompliance}>
                        Other arrangement:
                        <br />
                        <textarea
                          value={this.state.customCompliance}
                          onChange={this.handelCustomCompliance}
                          className="custom-textarea"
                        />
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
                <div className={`current-section ${this.state.prog === 9 ? 'show' : ''}`} />
              </div>
              <div className="question" id="finished">
                <div className="new-contract rounded">
                  <div className="text-center finished">
                    <img src={this.state.formIsValid ? GreenCheck : noGo} alt="green check" />
                    {this.resultsBasedOnCompletingForm()}
                    {this.state.successSaving && (
                      <div className="alert-wrapper">
                        <Alert message={`Successfully saved contract!`} type="success" showIcon />
                      </div>
                    )}
                    {this.state.errorSaving && (
                      <div className="alert-wrapper">
                        <Alert
                          message={`Error saving contract. Please review questions above.`}
                          type="error"
                          showIcon
                        />
                      </div>
                    )}

                    <Button
                      className="link blue"
                      loading={this.state.loading}
                      onClick={this.handleSubmit}
                      disabled={this.state.formIsValid ? false : true}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          {this.contractFooter()}
        </div>
      </div>
    );
  }
}
export default Form.create()(ContractForm);
