import React, { Component } from 'react';
import Logo from '../assets/logo3x.png';
import MagicDoc from '../assets/magic-doc3x.png';
import Clock from '../assets/clock-o3x.png';
import Lock from '../assets/lock-o3x.png';
import Heart from '../assets/heart.png';
import { Link } from 'react-router-dom';
import Clickety from '../clickety/clickety_post';
import './home.css';
class Home extends Component {
  componentDidMount() {
    document.body.classList.remove('admin');
    localStorage.removeItem('token');
    Clickety.recordCampaign();
  }

  render() {
    return (
      <div>
        <div id="main-display">
          <div id="logo">
            <img src={Logo} alt="logo" />
          </div>
          <h1>Secure your pet's future</h1>
          <p>With Pet Prenup, you and your partner can create a personalized custody agreement for your pets.</p>
          <Link
            to={`/plans?completion_goal_id=${process.env.REACT_APP_VIEW_PLANS}`}
            className="link aqua no-border get-started"
            onClick={() => Clickety.recordGoal(process.env.REACT_APP_VIEW_PLANS)}
          >
            Get Started
          </Link>
        </div>
        <div id="get-started" className="container">
          <img src={Heart} className="top-heart" alt="heart" />
        </div>
        <div className="home-divider" />
        <div className="container features">
          <div className="header-text home">Simple, fast, forever</div>
          <div className="row">
            <div className="col">
              <img src={MagicDoc} alt="magic" />
              <div className="col-header">So doggone simple</div>
              <p>
                We took a complicated contract and put it in terms even your pet could understand… well, maybe not
                Whiskers.
                <span role="img" aria-label="cat saying on no">
                  🙀
                </span>
              </p>
            </div>
            <div className="col">
              <img src={Clock} alt="clock" />
              <div className="col-header">Fast and Easy</div>
              <p>
                Create your digitally signed agreement in minutes! It's legally binding, signed, sealed and delivered
                with the push of a button.
              </p>
            </div>
            <div className="col">
              <img src={Lock} alt="lock" />
              <div className="col-header">Stored fur-ever</div>
              <p>
                Feel confident knowing that your digital contract can be stored in the cloud forever… access your
                agreement anytime, anywhere.
                <span role="img" aria-label="clouds">
                  ☁️
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginBottom: '85px' }} />
      </div>
    );
  }
}
export default Home;
