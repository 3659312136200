import { notification } from 'antd';

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
    duration: 3,
  });
};

export default openNotificationWithIcon;
