import React, { Component } from 'react';
import './legal.css';

class PrivacyPolicy extends Component {
  componentDidMount() {
    document.body.classList.add('legal');
  }

  componentWillUnmount() {
    document.body.classList.remove('legal');
  }

  render() {
    return (
      <div class="container">
        <h1>PET PRENUP PRIVACY POLICY</h1>
        <p>
          At Pet Prenup LLC (referred to as "Company," "us" or "we"), we recognize that your privacy is important. This
          Policy discloses the privacy practices for the Company's family of Websites (collectively, the "Site"), as
          well as related products and services we may offer to you (collectively referred to as the "Services"). This
          Policy also covers how personal and other information that we receive or collect about you is treated. Please
          read the information below to learn the following regarding your use of this Site.
        </p>
        <p>
          You acknowledge that this Privacy Policy is designed to be read in connection with the Site Terms of Service,
          which are available at <a href="https://www.petprenup.com/tos">https://www.petprenup.com/tos</a>, and that by
          accessing or using our Site, you agree to be bound by the Site&rsquo;s Terms of Service, as well as this
          Policy.
        </p>
        <p>
          We reserve the right to change this Privacy Policy from time to time. We will notify you about significant
          changes in the way we treat personal information by placing a notice on our site and by updating any privacy
          information on this page. Your continued use of the Site or Services available through this Site after such
          modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) your agreement to abide
          and be bound by that Policy.
        </p>
        <p>This Privacy Policy was last updated on January 1, 2019.</p>
        <p>
          If you have any questions about this Policy, please feel free to contact us at support@petprenup.com or by
          visiting{' '}
          <a href="https://www.petprenup.com/contact" target="_blank" rel="noopener noreferrer">
            https://www.petprenup.com/contact
          </a>
          .
        </p>
        <p>
          IMPORTANT: BY USING THE SITE OR THE OFFERINGS AVAILABLE ON THIS SITE, YOU GIVE YOUR CONSENT THAT ALL PERSONAL
          DATA THAT YOU SUBMIT MAY BE PROCESSED BY US IN THE MANNER AND FOR THE PURPOSES DESCRIBED BELOW. IF YOU DO NOT
          AGREE TO THESE TERMS AND CONDITIONS, DO NOT USE THE SITE.
        </p>
        <h2>1. Types of Information We Collect</h2>
        <p>
          In order to better provide you with the functionality of the Pet Prenup website, we collect two types of
          information about our users: Personally Identifiable Information ("PII") and Aggregate Information.
        </p>
        <p>
          Personally Identifiable Information: This refers to information that lets us know the specifics of who you
          are. When you engage in certain activities on this Site, such as registering for an account, submitting
          content, or posting content on the Site, or sending us feedback, we may ask you to provide certain information
          about yourself.
        </p>
        <p>
          Examples of PII may include your first and last name, email address, mailing address (including zip code),
          telephone number, photographs or other images, and other identifying information. When purchasing products or
          services on the Site, you may be asked to provide a credit card number.
        </p>
        <p>
          Aggregate Information: This refers to information that does not by itself identify a specific individual. We
          gather certain information about you based upon where you visit on our Site and what other sites may have
          directed you to us. This information, which is collected in a variety of different ways, is compiled and
          analyzed on both a personal and an aggregated basis. This information may include the Site's uniform resource
          locator ("URL") that points to the site you just came from, which URL you go to after visiting our Site,
          details about the web browser you are using, and your Internet Protocol ("IP") address.
        </p>
        <h2>2. How We Collect and Use Information</h2>
        <p>
          We do not collect any PII about you unless you voluntarily provide it to us. However, you may be required to
          provide certain PII to us when you elect to use certain products or services available on the Site. These may
          include: (a) registering for an account on our Site; (b) sending us an email message; (c) submitting a form or
          transmitting other information by telephone or letter; or (d) submitting your credit card or other payment
          information when ordering and purchasing products and services on our Site. When processing certain
          information, such as payment information with affiliated banking institutions or payment processors, we
          encrypt the transaction, using Secure Socket Layer (SSL) encryption technology, in order to prevent your PII
          from being stolen or intercepted. Additionally, your credit card information is never stored on our servers.
        </p>
        <p>
          We will primarily use your PII to provide product or service offerings to you. We will also use certain forms
          of PII to enhance the operation of our Site, improve our marketing and promotional efforts, statistically
          analyze Site use, improve our product and service offerings, and customize our Site's content, layout, and
          services. We pay use PII to engage in remarketing, both using our internal systems or with third-party service
          providers. We may use PII to deliver information to you and to contact you regarding administrative notices.
          Finally, we may use your PII to resolve disputes, troubleshoot problems, and enforce our agreements with you,
          including our Site Terms of Service and this Privacy Policy.
        </p>
        <p>
          We may also collect, or our third party advertising partners may collect, certain Aggregate Information. For
          example, we may use your IP address to diagnose problems with our servers, software, to administer our Site
          and to gather demographic information. Our third party advertising partners may also provide us with aggregate
          and individual reports that will tell us how many ads were presented and clicked upon at out Site.
        </p>
        <h2>3. Cookies</h2>
        <p>
          Depending on how you use our Site, we will store cookies on your computer in order to collect certain
          aggregate data about our users and to customize certain aspects of your specific user experience. A cookie is
          a small data-text file which is stored on your computer that uniquely identifies your browser. Cookies may
          also include more personalized information, such as your IP address, browser type, the server your computer is
          logged onto, the area code and zip code associated with your server, and your first name to welcome you back
          to our Site. We may use cookies to perform tasks such as: monitoring aggregate site usage metrics, storing and
          remembering your passwords (if you allow us to do so), storing account and advertising preferences that you
          have set, and personalizing the Services we make available to you.
        </p>
        <p>
          We may also use an outside advertising partner to display banner advertisements on our Site. As part of their
          service, they will place a separate cookie on your computer. We and our third party ad server will collect and
          use Aggregate Information about you, such as your IP address, browser type, the server your computer is logged
          onto, the area code and zip code associated with your server and whether you responded to a particular ad.
          Other advertisers may also place banner ads on our Site in the same manner as above.
        </p>
        <p>
          Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or
          to indicate when a cookie is being sent. However, some aspects of the Site may not function properly if you
          elect to disable cookies.
        </p>
        <p>
          We use the Google Analytics Advertising Features which includes AdWords remarketing services to advertise on
          third party websites to visitors of the our website. Third-party vendors, including Google, use cookies to
          provide advertisements based on a user's past visits to various websites, including our website. This
          remarketing can be seen in the form of advertisements on Google search result pages and other sites in the
          Google Display Network. Any data collected will be used in accordance with our privacy policy and Google's
          privacy policy. To opt-out of remarketing through Google's use of cookies, by changing your preferences in
          your Google Ad Settings, click on the following link:{' '}
          <a href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>. To opt-out of third-party
          vendor's use of cookies for remarketing (also known as interest-based advertising) visit the Network
          Advertising Initiative Opt-Out page link:{' '}
          <a href="http://www.networkadvertising.org/managing/opt_out.asp">
            http://www.networkadvertising.org/managing/opt_out.asp
          </a>
          . To review additional Google Analytic opt-out options, click on the following link:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout/">Google Analytics' Opt-Out Browser Add-On</a>.
        </p>
        <p>
          We partner with Clickety for affiliate marketing and Clickety may set a cookie on your computer in order to
          ensure that our affiliates are properly credited for their referral. You can learn more at{' '}
          <a href="https://clickety.com/">https://clickety.com/</a>.
        </p>
        <h2>4. Release of Information</h2>
        <p>
          We will not sell, trade, or rent your PII to others, except as outlined in this Privacy Policy. We provide
          some of our product and service offerings through contractual arrangements made with affiliates, service
          providers, partners, and other third parties ("Service Partners"). We and our Service Partners may need to use
          some PII in order to perform tasks between our respective sites, or to deliver products or services to you.
          For example, we must release your credit card information to the card-issuing bank to confirm payment for
          products and services purchased on this Site; release your address information to the delivery service to
          deliver products that you ordered; release your PII to detect and prevent fraudulent activity and
          transactions; and provide order information to third parties that help us provide customer service.
        </p>
        <p>
          We will encourage our Service Partners to adopt and promote strong privacy policies. However, the use of your
          PII by our Service Partners is governed the respective terms of service and privacy policies of those
          providers, and is not subject to our control. Except as otherwise discussed in this Privacy Policy, this
          document only addresses the use and disclosure of information we collect from you. Other Sites accessible
          through this Site, including our Advertising and Service Partners, have their own privacy policies and data
          collection, use, and disclosure practices. Please consult each Site's terms of serivce and privacy policy. We
          are not responsible for the policies or practices of third parties.
        </p>
        <p>
          Occasionally we may be required by law enforcement or judicial authorities to provide PII to the appropriate
          governmental authorities. In such cases, we will disclose PII upon receipt of a court order, subpoena, or to
          cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in
          identifying those who use our services for illegal activities. We reserve the right to report to law
          enforcement agencies any activities that we in good faith believe to be unlawful.
        </p>
        <p>
          We may also provide Aggregate Information about our customers' sales, traffic patterns, and related Site
          information to third party service providers.
        </p>
        <h2>5. Updating, Correcting, or Deleting Information</h2>
        <p>
          We believe you should have the ability to access and edit the PII that you have provided to us. You may change
          any of your PII in your account online at any time by linking to your account in accordance with instructions
          posted elsewhere on this Site. You may also access and correct your personal information and privacy
          preferences by emailing or writing us at:
        </p>
        <p>
          Pet Prenup LLC
          <br /> Attn: Privacy Compliance Officer
          <br /> 7904 E Chaparral Rd
          <br /> Ste A110 PMB 473
          <br /> Scottsdale, AZ 85250
          <br /> Email: support@petprenup.com
        </p>
        <p>Please include your name, account number, and email address when you contact us.</p>
        <p>We encourage you to promptly update your PII if it changes.</p>
        <p>
          You may ask to have the information on your account deleted or removed; however, some information, such as
          past transactions, logs of technical support calls, or other transactional information may not be deleted. In
          addition, it may be impossible to completely delete your information without some residual information because
          of backups.
        </p>
        <h2>6. User Choices on Collection and Use of Information</h2>
        <p>
          We may, from time to time, send you email regarding new products and services that we feel may interest you.
          Only we (or agents working on behalf of us and under confidentiality agreements) will send you these
          solicitations, and only if you have previously indicated that you wish to receive them. If you do not want to
          receive solicitations from us, you can "opt-out" by accessing your account online editing your account
          information to no longer receive such offers and mailings.
        </p>
        <p>
          You also have choices with respect to cookies, as described above. By modifying your browser preferences, you
          have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you
          choose to reject all cookies some parts of our Site may not work properly in your case.
        </p>
        <h2>7. Security of Your PII</h2>
        <p>
          At our Site you can be assured that your PII is secure, consistent with current industry standards. We strive
          to take appropriate security measures to protect against unauthorized access to or unauthorized alteration,
          disclosure or destruction of your PII. For example:
        </p>
        <ul style={{ listStyleType: 'disc', paddingLeft: '40px' }}>
          <li>
            We work hard to ensure that the data we collect is reliable, accurate, complete, and current. We may keep
            collected information indefinitely and use it pursuant to the Site&rsquo;s Terms of Service and Privacy
            Policy or to comply with any applicable legal or ethical reporting or document retention requirements.
          </li>
          <li>
            We limit access to PII only to specific employees, contractors, and agents who have a reasonable need to
            come into contact with your information. For example, we may provide members of our technical support team
            with limited access to your account in order to allow them to troubleshoot problems you may be having with
            the Site.
          </li>
          <li>
            Additionally, we also employ a number of physical, electronic, and procedural safeguards to protect PII. Our
            secure servers are protected by industry-standard encryption, and our servers reside behind firewalls and
            employ high-level password protection.
          </li>
          <li>
            Finally, access by you to your PII is available through a password and unique customer ID selected by you.
            This password is encrypted. We recommend that you do not divulge your password to anyone.
          </li>
        </ul>
        <p>
          In order to most effectively serve you, credit card transactions and order fulfillment are handled by
          established third party banking institutions and processing agents (such as Stripe). They receive the
          information needed to verify and authorize your credit card or other payment information and to process and
          ship your order.
        </p>
        <p>
          Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100%
          secure. As a result, while we strive to protect your PII, you acknowledge that: (a) there are security and
          privacy limitations inherent to the Internet which are beyond our control; and (b) the security, integrity and
          privacy of any and all information and data exchanged between you and us through this Site cannot be
          guaranteed.
        </p>
        <h2>8. Miscellaneous</h2>
        <p>
          You must be an individual who is at least 18 years old to have our permission to use this Site. Our policy is
          that we do not knowingly collect, use, or disclose PII about minor visitors.
        </p>
        <p>
          You should also be aware that when you disclose Personally Identifiable Information (i.e. your name, email
          address, etc.) in the public areas on this Site, that information, along with any information disclosed in
          your communication, can be collected and used by third parties and may result in unsolicited messages
          (including unwanted spam messages) from third parties. Such activities are beyond our control and this Policy
          does not apply to such information. Please consult our Site Terms of Service for our Site Conduct policies at{' '}
          <a href="https://www.petprenup.com/tos">https://www.petprenup.com/tos</a>. In the event of a conflict between
          this Privacy Policy and the Terms of Service, the Terms of Service are controlling.
        </p>
        <p>
          If you have any questions, concerns, or inquiries about our Privacy Policy, our use of your PII, or our
          privacy practices, please contact us at support@petprenupcom.
        </p>
        <p>You can also reach our Privacy Compliance Officer by sending written correspondence to:</p>
        <p>
          Pet Prenup LLC
          <br /> Attn: Privacy Compliance Officer
          <br /> 7904 E Chaparral Rd
          <br /> Ste A110 PMB 473
          <br /> Scottsdale, AZ 85250
        </p>
      </div>
    );
  }
}
export default PrivacyPolicy;
