import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Modal } from 'antd';
import Network from '../services/network';
import './contracts.css';
import Close from '../assets/close@2x.png';
import openNotificationWithIcon from '../utilities/notification';
const TextArea = Input.TextArea;

const FormItem = Form.Item;

class RequestChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      contract: this.props.contract,
      loading: false,
    };
  }

  // submit
  handleSubmit = e => {
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let contract = {
          _id: this.state.contract._id,
          changes: values.changes,
        };
        this.loadingTrue();
        contract._id = this.state.contract._id;
        Network.put('/contracts/request-changes', contract, this.networkResponse);
      } else {
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', 'Error saving changes. Please try again.');
      } else if (res.status === 201 || res.status === 200) {
        this.props.closeDrawerAndUpdateView();
        openNotificationWithIcon('success', 'Changes Requested', 'Changes sent for review');
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  /**
   * Modal methods
   */
  onClose = () => {
    this.props.closeModal();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        onCancel={this.onClose}
        className="styled-modal"
        footer={[
          <Button
            key="1"
            className="link darker sign-btn"
            loading={this.state.loading}
            htmlType="submit"
            onClick={() => document.getElementById('submit-btn').click()}
          >
            Confirm Signature
          </Button>,
        ]}
      >
        <img src={Close} className="edit-closer" alt="closer" onClick={this.onClose} />
        <Form onSubmit={this.handleSubmit}>
          <h2 className="drawer-header">Request Changes</h2>

          <div id="edit-form">
            <Row gutter={16}>
              <Col span={24}>
                <FormItem label="List your requested changes">
                  {getFieldDecorator('changes', {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: 'Please state your changes.' }],
                  })(<TextArea placeholder="Changes..." />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <button id="submit-btn" type="submit" />
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(RequestChanges);
