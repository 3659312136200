import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button, Alert } from 'antd';
import Network from '../services/network';
import './auth.css';
import AuthWrapper from './auth-wrapper';
const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      successfulSignin: false,
      errorSigningIn: false,
      serverErrors: '',
      serverSuccess: '',
      toDashboard: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('session');
  }

  componentWillUnmount() {
    document.body.classList.remove('session');
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const user = { email: values.email, password: values.password };

        this.loadingTrue();
        Network.post('/signin', user, this.networkResponse);
      }
    });
  };

  // network stuff
  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  networkResponse = res => {
    this.loadingFalse();
    if (res.status) {
      if (res.status === 401) {
        this.setState({ errorSigningIn: true, serverErrors: 'Please check your email and password' });
      } else if (res.status === 201 || res.status === 200) {
        // save to local storage
        localStorage.setItem('token', res.data.token);
        this.props.userHasAuthenticated(true);
      }
    }
  };

  closeAlert = () => {
    this.setState({ successfulSignin: false, errorSigningIn: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <AuthWrapper>
        <div id="signin">
          <h2 className="header-text">Sign In</h2>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <FormItem>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  {
                    required: true,
                    message: 'Please enter a valid email',
                  },
                ],
              })(<Input placeholder="Email" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please type your password' }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </FormItem>
            <FormItem>
              {this.state.successfulSignin && (
                <Alert
                  message={this.state.serverSuccess}
                  type="success"
                  closable
                  afterClose={this.closeAlert}
                  showIcon
                />
              )}
              {this.state.errorSigningIn && (
                <Alert message={this.state.serverErrors} type="error" closable afterClose={this.closeAlert} showIcon />
              )}
            </FormItem>
            <FormItem>
              <Button className="link lg blue" loading={this.state.loading} htmlType="submit">
                Sign in
              </Button>
            </FormItem>
          </Form>
          <br />
          <Link className="login-form-forgot" to="/forgot-password">
            Forgot password?
          </Link>
          <br />
          Don't have an account? Choose a <Link to="/plans">plan</Link> and register.
        </div>
      </AuthWrapper>
    );
  }
}

export default Form.create()(SignIn);
