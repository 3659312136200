import React, { Component } from 'react';
import { Form, Input, Row, Col, Radio, Button, Modal } from 'antd';
import Network from '../services/network';
import moment from 'moment';
import ImageUpload from './image-upload';
import Close from '../assets/close@2x.png';
import checkDate from '../utilities/validateDate';
import openNotificationWithIcon from '../utilities/notification';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class ContractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageUrl: null,
      originator: 'You',
      partner: 'Partner',
      petName: 'your pet',
      originatorDays: 0,
      partnerDays: 0,
      customCustody: '',
      customCare: '',
      isEditing: false,
      loading: false,
      contract: null,
      visible: this.props.visible,
      imagesToRemove: [],
      dobFocus: false,
    };
  }

  componentWillMount() {
    this.setContract(this.props.contract);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.contract !== prevProps.contract) {
      this.setContract(this.props.contract);
    }

    if (this.props.form !== prevProps.form) {
      this.updateFormItems();
    }
  }

  setContract = contract => {
    let c = contract;
    this.setState(
      {
        isEditing: true,
        contract: c,
        currentImageUrl: c.image,
        customCare: c.customCare,
        customCustody: c.customCustody,
        originatorDays: c.originatorDays,
        partnerDays: c.partnerDays,
        customHolidays: c.customHolidays,
        customCompliance: c.customCompliance,
        customOtherNeeds: c.customOtherNeeds,
      },
      () => {
        this.props.form.setFieldsValue({
          petName: c.petName,
          species: c.species,
          breed: c.breed,
          chipId: c.chipId,
          uniqueMarkings: c.uniqueMarkings,
          firstName: c.userId.firstName,
          lastName: c.userId.lastName,
          dateOfBirth: moment(c.userId.dateOfBirth).format('MM/DD/YYYY'),
          partnerFirstName: c.partnerFirstName,
          partnerLastName: c.partnerLastName,
          partnerEmail: c.partnerEmail,
          primaryGuardian: c.primaryGuardian,
          becameMember: c.becameMember,
          custody: c.custody,
          care: c.care,
          holidays: c.holidays,
          compliance: c.compliance,
          otherNeeds: c.otherNeeds,
        });
      }
    );
  };

  updateFormItems = () => {
    const { getFieldValue, getFieldsValue } = this.props.form;

    // update pet name
    const petName = getFieldValue('petName');
    if (petName !== undefined) {
      this.setState({ petName: petName });
    }

    // update originator
    const originator = getFieldsValue(['firstName', 'lastName']);
    if (originator.firstName && originator.lastName) {
      this.setState({
        originator: originator.firstName + ' ' + originator.lastName,
      });
    } else {
      this.setState({ originator: 'you' });
    }

    // update partner
    const partner = getFieldsValue(['partnerFirstName', 'partnerLastName']);
    if (partner.partnerFirstName && partner.partnerLastName) {
      this.setState({
        partner: partner.partnerFirstName + ' ' + partner.partnerLastName,
      });
    } else {
      this.setState({ partner: 'partner' });
    }
  };

  /**
   * Validate date
   */
  validateDate = e => {
    const value = e.target.value;
    const date = checkDate(value);
    if (date.success) {
      e.target.value = date.success;
    } else {
      openNotificationWithIcon('error', 'Error', 'Incorrect birthday format. Please try again.');
      e.target.value = '';
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    // if (this.state.currentImageUrl === null) {
    //   openNotificationWithIcon('error', 'Error', 'Please include a photo of your beloved pet.');
    //   document.getElementById('uploader').scrollIntoView();
    //   return;
    // }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.loadingTrue();
        let contract = {
          petName: values.petName,
          species: values.species,
          breed: values.breed,
          chipId: values.chipId,
          uniqueMarkings: values.uniqueMarkings,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: moment(values.dateOfBirth).toDate(),
          partnerFirstName: values.partnerFirstName,
          partnerLastName: values.partnerLastName,
          partnerEmail: values.partnerEmail,
          primaryGuardian: values.primaryGuardian,
          becameMember: values.becameMember,
          custody: values.custody,
          customCustody: this.state.customCustody,
          originatorDays: this.state.originatorDays,
          partnerDays: this.state.partnerDays,
          care: values.care,
          customCare: this.state.customCare,
          holidays: values.holidays,
          customHolidays: this.state.customHolidays,
          compliance: values.compliance,
          customCompliance: this.state.customCompliance,
          otherNeeds: values.otherNeeds,
          customOtherNeeds: this.state.customOtherNeeds,
          image: this.state.currentImageUrl,
          imagesToRemove: this.state.imagesToRemove,
        };

        if (this.state.contract.statusCode === 4) {
          // 4 means there were requested changes
          contract.statusCode = 2; // ready for contractor to email
        }
        this.loadingTrue();
        contract._id = this.state.contract._id;
        Network.put('/contracts', contract, this.networkResponse);
      } else {
        openNotificationWithIcon('error', 'Error', 'Error updating contract. Please review form.');
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', 'Error saving contract. Please try again.');
      } else if (res.status === 201 || res.status === 200) {
        this.props.closeDrawerAndUpdateView();
        openNotificationWithIcon('success', 'Success', 'Changes saved.');
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  handelOriginatorDays = e => {
    this.setState({ originatorDays: e.target.value });
  };

  handelPartnerDays = e => {
    this.setState({ partnerDays: e.target.value });
  };

  handelCustomCustody = e => {
    this.props.form.setFieldsValue({
      custody: e.target.value,
    });
    this.setState({ customCustody: e.target.value });
  };

  handelCustomCare = e => {
    this.props.form.setFieldsValue({
      care: e.target.value,
    });
    this.setState({ customCare: e.target.value });
  };

  handelCustomHolidays = e => {
    this.props.form.setFieldsValue({
      holidays: e.target.value,
    });
    this.setState({ customHolidays: e.target.value });
  };

  handelCustomCompliance = e => {
    this.props.form.setFieldsValue({
      compliance: e.target.value,
    });
    this.setState({ customCompliance: e.target.value });
  };

  handelCustomOtherNeeds = e => {
    this.props.form.setFieldsValue({
      otherNeeds: e.target.value,
    });
    this.setState({ customOtherNeeds: e.target.value });
  };

  /**
   * Drawer methods
   */
  onClose = () => {
    this.props.closeDrawer();
  };

  setCurrentImageUrl = currentImageUrl => {
    this.setState({ currentImageUrl });
  };

  dobFocused = () => {
    this.setState({ dobFocus: true });
    setTimeout(() => {
      this.setState({ dobFocus: false });
    }, 2000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        closable={false}
        onClose={this.onClose}
        onCancel={this.onClose}
        visible={this.props.visible}
        className="styled-modal"
        style={{ top: 60 }}
        footer={[
          <Button
            key="1"
            className="link darker sign-btn"
            loading={this.state.loading}
            htmlType="submit"
            onClick={() => document.getElementById('submit-btn').click()}
          >
            Update Document
          </Button>,
        ]}
      >
        <img src={Close} className="edit-closer" alt="closer" onClick={this.onClose} />
        <Form onSubmit={this.handleSubmit} className="edit-form">
          <h2 className="drawer-header">Edit Contract</h2>

          <div className={`contact-questions`}>
            <div className="question">
              <h2>About your pet</h2>
              <p>Give us the details! Tell us about your pet.</p>
              <Row gutter={16}>
                <Col>
                  <FormItem label="Pet name">
                    {getFieldDecorator('petName', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your pets name.',
                        },
                      ],
                    })(<Input placeholder="Pet Name" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Species">
                    {getFieldDecorator('species', {
                      validateTrigger: ['onBlur'],
                      rules: [{ required: true, message: 'Please enter a species.' }],
                    })(<Input placeholder="Dog, cat, bird, etc...." />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Breed(s)">
                    {getFieldDecorator('breed', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a breed(s).',
                        },
                      ],
                    })(<Input placeholder="Breed(s). Separate with commas" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Chip ID number">
                    {getFieldDecorator('chipId', {})(<Input placeholder="Chip ID number" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Unique markings">
                    {getFieldDecorator('uniqueMarkings', {})(<Input placeholder="Unique Markings" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Photo">
                    <ImageUpload currentImageUrl={this.state.contract.image} callback={this.setCurrentImageUrl} />
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div className="question">
              <h2>About you</h2>
              <p>We already have some of this information, just look over it to make sure it’s correct.</p>
              <Row gutter={16}>
                <Col>
                  <FormItem label="First name">
                    {getFieldDecorator('firstName', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your first name.',
                        },
                      ],
                    })(<Input placeholder="First Name" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Last name">
                    {getFieldDecorator('lastName', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your last name.',
                        },
                      ],
                    })(<Input placeholder="Last Name" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Birthday" extra="Example: 02/24/1955">
                    {getFieldDecorator('dateOfBirth', {
                      validateTrigger: ['onChange'],
                      rules: [
                        {
                          required: true,
                          message: 'Please fill out your date of birth.',
                        },
                      ],
                    })(<Input placeholder="Date of Birth" onBlur={this.validateDate} />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div className="question">
              <h2>About your partner</h2>
              <p>
                Contracts require a signature from you and your partner. Let us know who they are and where to send it.
              </p>
              <Row gutter={16}>
                <Col>
                  <FormItem label="Partner's first name">
                    {getFieldDecorator('partnerFirstName', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: "Please enter your partner's first name.",
                        },
                      ],
                    })(<Input placeholder="First Name" />)}
                  </FormItem>
                </Col>
                <Col>
                  <FormItem label="Partner's last name">
                    {getFieldDecorator('partnerLastName', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: "Please enter your partner's last name.",
                        },
                      ],
                    })(<Input placeholder="Last Name" />)}
                  </FormItem>
                </Col>

                <Col span={24}>
                  <FormItem label="Partner's email">
                    {getFieldDecorator('partnerEmail', {
                      validateTrigger: ['onBlur'],
                      rules: [
                        {
                          required: true,
                          message: "Please enter your partner's email.",
                        },
                      ],
                    })(<Input placeholder="Email" />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div className="question radio-offset">
              <h2>
                Who is {this.state.petName}
                's guardian?
              </h2>
              <p>The other person will automatically be the secondary guardian.</p>
              <FormItem>
                {getFieldDecorator('primaryGuardian', {
                  rules: [{ required: true, message: 'Please choose primary' }],
                })(
                  <RadioGroup>
                    <Radio value="originator">{this.state.originator}.</Radio>
                    <Radio value="partner">{this.state.partner}.</Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>How did {this.state.petName} become a member of the family?</h2>
              <p>What's the story here?</p>
              <FormItem>
                {getFieldDecorator('becameMember', {
                  rules: [
                    {
                      required: true,
                      message: 'Please choose an option.',
                    },
                  ],
                })(
                  <RadioGroup>
                    <Radio value="We adopted petName together">
                      We adopted <strong>{this.state.petName}</strong> together.
                    </Radio>
                    <Radio value="originator brought petName to the family">
                      <strong>{this.state.originator}</strong> brought <strong>{this.state.petName}</strong> to the
                      family.
                    </Radio>
                    <Radio value="partner brought petName to the family">
                      <strong>{this.state.partner}</strong> brought <strong>{this.state.petName}</strong> to the family.
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>Things happen in relationships..if they do, who will be responsible for {this.state.petName}?</h2>
              <p>The other person will automatically be the secondary guardian.</p>
              <FormItem>
                {getFieldDecorator('custody', {
                  rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                })(
                  <RadioGroup>
                    <Radio value="originator will have complete and total custody of petName">
                      <strong>{this.state.originator}</strong> will have complete and total custody of{' '}
                      <strong>{this.state.petName}</strong>.
                    </Radio>
                    <Radio value="partner will have complete and total custody of petName">
                      <strong>{this.state.partner}</strong> will have complete and total custody of{' '}
                      <strong>{this.state.petName}</strong>.
                    </Radio>
                    <Radio value="petName will alternate equally between originator and partner each and every week">
                      <strong>{this.state.petName}</strong> will alternate equally between{' '}
                      <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> each and every
                      week.
                    </Radio>

                    <Radio value="originator gets petName for originatorDays days and partner for partnerDays days...alternating between the same schedule">
                      <strong>{this.state.originator}</strong> gets <strong>{this.state.petName}</strong> for{' '}
                      <input
                        type="text"
                        value={this.state.originatorDays}
                        onChange={this.handelOriginatorDays}
                        id="originator-days"
                      />{' '}
                      days, and
                      <strong>{this.state.partner}</strong> for{' '}
                      <input
                        type="text"
                        value={this.state.partnerDays}
                        onChange={this.handelPartnerDays}
                        id="partner-days"
                      />{' '}
                      days,...alternating between the same schedule regularly.
                    </Radio>
                    <Radio value="originator will get full guardianship of petName, and partner will have visitation rights based on agreeable times and dates">
                      <strong>{this.state.originator}</strong> will get full guardianship of{' '}
                      <strong>{this.state.petName}</strong>, and <strong>{this.state.partner}</strong> will have
                      visitation rights based on agreeable times and dates.
                    </Radio>

                    <Radio value="partner will get full guardianship of petName, and originator will have visitation rights based on agreeable times and dates">
                      <strong>{this.state.partner}</strong> will get full guardianship of{' '}
                      <strong>{this.state.petName}</strong>, and <strong>{this.state.originator}</strong> will have
                      visitation rights based on agreeable times and dates.
                    </Radio>

                    <Radio value={this.state.customCustody}>
                      We’d prefer to create our own care/visitation agreement:
                      <br />
                      <textarea
                        value={this.state.customCustody}
                        onChange={this.handelCustomCustody}
                        className="custom-textarea"
                      />
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>Who will pay for everyday expenses?</h2>
              <p>Everyday expenses can be things like food, medicine, cleaning etc.</p>
              <FormItem>
                {getFieldDecorator('care', {
                  rules: [{ required: true, message: 'Please choose an option or write your own.' }],
                })(
                  <RadioGroup>
                    <Radio value="originator and partner will split costs.">
                      <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> will split
                      costs.
                    </Radio>
                    <Radio value="Whoever has petName at any given time is solely responsible for petName everyday expenses.">
                      Whoever has
                      <strong>{this.state.petName}</strong> at any given time is solely responsible for{' '}
                      <strong>{this.state.petName}</strong> everyday expenses.
                    </Radio>
                    <Radio value="originator will have complete responsibility for the cost of care, and will reimburse any costs that occur while in partner care.">
                      <strong>{this.state.originator}</strong> will have complete responsibility for the cost of care,
                      and will reimburse any costs that occur while in <strong>{this.state.partner}</strong> care.
                    </Radio>
                    <Radio value="partner will have complete responsibility for the cost of care, and will reimburse any costs that occur while in originator care.">
                      <strong>{this.state.partner}</strong> will have complete responsibility for the cost of care, and
                      will reimburse any costs that occur while in <strong>{this.state.originator}</strong> care.
                    </Radio>
                    <Radio value={this.state.customCare}>
                      We'd prefer to create an alternate care agreement instead.
                      <br />
                      <textarea
                        value={this.state.customCare}
                        onChange={this.handelCustomCare}
                        className="custom-textarea"
                      />
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>Other Needs</h2>
              <p>
                In the event that other, more expensive needs arise for {this.state.petName}, beyond basic necessities
                (such as medical care, medication, etc), the parties agree to split costs as follows:
              </p>
              <FormItem>
                {getFieldDecorator('otherNeeds', {
                  rules: [
                    {
                      required: true,
                      message: 'Please choose an option or write your own.',
                    },
                  ],
                })(
                  <RadioGroup>
                    <Radio value="originator and partner will split all costs 50%/50%">
                      <strong>{this.state.originator}</strong> and <strong>{this.state.partner}</strong> will split all
                      costs 50%/50%.
                    </Radio>
                    <Radio value="originator will be responsible for all costs">
                      <strong>{this.state.originator}</strong> will be responsible for all costs.
                    </Radio>
                    <Radio value="partner will be responsible for all costs">
                      <strong>{this.state.partner}</strong> will be responsible for all costs.
                    </Radio>
                    <Radio value={this.state.customOtherNeeds}>
                      We’d prefer to create our own arrangement:
                      <br />
                      <textarea
                        value={this.state.customOtherNeeds}
                        onChange={this.handelCustomOtherNeeds}
                        className="custom-textarea"
                      />
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>Holidays</h2>
              <p>The parties agree that {this.state.petName} will be shared on Holidays as follows:</p>
              <FormItem>
                {getFieldDecorator('holidays', {
                  rules: [
                    {
                      required: true,
                      message: 'Please choose an option or write your own.',
                    },
                  ],
                })(
                  <RadioGroup>
                    <Radio value="originator and partner will split costs.">
                      All holidays are to be shared equally as <strong>{this.state.originator}</strong> or{' '}
                      <strong>{this.state.partner}</strong>
                      ’s work permits, at times and dates to be agreed between the parties giving at least four weeks’
                      notice in advance.
                    </Radio>
                    <Radio value={this.state.customHolidays}>
                      We’d prefer to create our own holiday's arrangement:
                      <br />
                      <textarea
                        value={this.state.customHolidays}
                        onChange={this.handelCustomHolidays}
                        className="custom-textarea"
                      />
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
            <div className="question radio-offset">
              <h2>Compliance</h2>
              <p>
                The parties agree that in the event that the matters set out in this agreement are ignored by either
                party to the extent that the lack of compliance amounts to neglect, the pet will revert absolutely to
                the other party and the defaulting party will pay all the costs that were agreed to be paid by them in
                any event for:
              </p>
              <FormItem>
                {getFieldDecorator('compliance', {
                  rules: [
                    {
                      required: true,
                      message: 'Please choose an option or write your own.',
                    },
                  ],
                })(
                  <RadioGroup>
                    <Radio value="The duration of the ownership of the pet">
                      The duration of the ownership of the pet.
                    </Radio>
                    <Radio value="By order of any court">By order of any court.</Radio>
                    <Radio value={this.state.customCompliance}>
                      We’d prefer to create our own compliance arrangement:
                      <br />
                      <textarea
                        value={this.state.customCompliance}
                        onChange={this.handelCustomCompliance}
                        className="custom-textarea"
                      />
                    </Radio>
                  </RadioGroup>
                )}
              </FormItem>
            </div>
          </div>
          <button id="submit-btn" type="submit" />
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(ContractForm);
