import React, { Component } from 'react';
import { Form, Input, Row, Col, Modal, Button } from 'antd';
import Network from '../services/network';
import './contracts.css';
import TextArea from 'antd/lib/input/TextArea';
import Close from '../assets/close@2x.png';
import openNotificationWithIcon from '../utilities/notification';
const FormItem = Form.Item;

class RequestSignature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: this.props.contract,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.props.form.setFieldsValue({ email: this.props.contract.partnerEmail });
  };

  // submit
  handleSubmit = e => {
    this.setState({
      signatureError: false,
    });
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let contract = {
          _id: this.state.contract._id,
          email: values.email,
          note: values.note,
        };
        this.loadingTrue();
        contract._id = this.state.contract._id;
        Network.put('/contracts/request-signature', contract, this.networkResponse);
      } else {
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();

    if (res && res.status) {
      if (res.status >= 400) {
        openNotificationWithIcon('error', 'Error', 'Error saving contract. Please try again.');
      } else if (res.status === 201 || res.status === 200) {
        // this.props.closeModalAndUpdateView();
        this.props.finishedOpenModal();
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  /**
   * Modal methods
   */
  onClose = () => {
    this.props.closeModal();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        onCancel={this.onClose}
        className="styled-modal"
        footer={[
          <Button
            key="1"
            className="link darker sign-btn"
            loading={this.state.loading}
            htmlType="submit"
            onClick={() => document.getElementById('submit-btn').click()}
          >
            Request Signature
          </Button>,
        ]}
      >
        <img src={Close} className="edit-closer" alt="closer" onClick={this.onClose} />
        <Form onSubmit={this.handleSubmit}>
          <h2 className="drawer-header">Request Signature</h2>

          <Row gutter={16}>
            <Col span={24}>
              <FormItem label={`The request will be sent to ${this.state.contract.partnerFirstName}`}>
                {getFieldDecorator('email', {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      type: 'email',
                      message: 'Invalid email',
                    },
                    {
                      required: true,
                      message: 'Please enter your email',
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem label="Anything you need to tell them">{getFieldDecorator('note', {})(<TextArea />)}</FormItem>
            </Col>
          </Row>
          <button id="submit-btn" type="submit" />
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(RequestSignature);
