import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Network from '../services/network';
import SignatureCanvas from 'react-signature-canvas';
import Close from '../assets/close@2x.png';
import openNotificationWithIcon from '../utilities/notification';
import './contracts.css';

const FormItem = Form.Item;

class SignatureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      contract: this.props.contract,
      loading: false,
      signatureError: false,
    };
  }

  componentDidMount() {
    this.props.form.setFieldsValue({
      firstName: this.props.contract.firstName,
      lastName: this.props.contract.lastName,
    });
  }

  componentDidUpdate = prevState => {
    if (prevState.signatureError) {
      this.setState({ signatureError: false });
    }
  };

  // submit
  handleSubmit = e => {
    this.setState({
      signatureError: false,
    });
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFields((err, values) => {
      if (!err && this.sigPad.toData().length) {
        let contract = {
          _id: this.state.contract._id,
          signatureFirstName: values.firstName,
          signatureLastName: values.lastName,
          signature: this.sigPad.getTrimmedCanvas().toDataURL(),
        };
        contract._id = this.state.contract._id;
        Network.put('/contracts/signature', contract, this.networkResponse);
      } else {
        this.setState({ signatureError: true });
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status >= 400) {
        openNotificationWithIcon('error', 'Error', 'Error signing contracts. Please try again.');
      } else if (res.status === 201 || res.status === 200) {
        this.props.closeDrawerAndOpenSendRequest();
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  // signature methods
  sigPad = {};
  clear = e => {
    e.preventDefault();
    this.sigPad.clear();
    this.setState({
      trimmedDataURL: null,
      signatureError: false,
    });
  };

  signatureError = () => {
    if (this.state.signatureError) {
      return (
        <div style={{ color: '#FFB17B' }} className="ant-form-explain">
          Signature cannot be blank
        </div>
      );
    }
    return '';
  };

  /**
   * Drawer methods
   */
  onClose = () => {
    this.props.closeDrawer();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        closable={false}
        onClose={this.onClose}
        onCancel={this.onClose}
        visible={this.props.visible}
        className="styled-modal"
        footer={[
          <Button
            key="1"
            className="link darker sign-btn"
            loading={this.state.loading}
            htmlType="submit"
            onClick={() => document.getElementById('submit-btn').click()}
          >
            Confirm Signature
          </Button>,
        ]}
      >
        <Form onSubmit={this.handleSubmit}>
          <img src={Close} className="edit-closer" alt="closer" onClick={this.onClose} />

          <h2 className="drawer-header">Sign Now</h2>
          <div id="edit-form">
            <div className="form-label">Type your full legal name</div>
            <Row gutter={16}>
              <Col span={24}>
                <FormItem label="First name">
                  {getFieldDecorator('firstName', {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: 'Please input your first name.' }],
                  })(<Input placeholder="First Name" />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label="Last name">
                  {getFieldDecorator('lastName', {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: 'Please input your last name.' }],
                  })(<Input placeholder="Last Name" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Link style={{ marginTop: '15px' }} to="#" className="float-right" onClick={this.clear}>
                  Clear
                </Link>
                <div className="form-label ant-form-item-required">Write your signature in the box below.</div>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: 'sigCanvas' }}
                  ref={ref => {
                    this.sigPad = ref;
                  }}
                />
                {this.signatureError()}
              </Col>
            </Row>
          </div>
          <button id="submit-btn" type="submit" />
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(SignatureForm);
