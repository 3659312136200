import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Clickety from '../clickety/clickety_post';
import './plans.css';

class Plan extends Component {
  goal_id = plan => {
    switch (plan) {
      case 'starter':
        return process.env.REACT_APP_VISIT_WITH_STARTER;
      case 'deluxe':
        return process.env.REACT_APP_VISIT_WITH_DELUXE;
      case 'unlimited':
        return process.env.REACT_APP_VISIT_WITH_UNLIMITED;
    }
  };

  render() {
    return (
      <div className={`col ${this.props.class}`}>
        {this.props.plan === 'deluxe' ? (
          <div className="best-value">Best Value!</div>
        ) : (
          <div className="best-value">&nbsp;</div>
        )}

        <div className={`${this.props.color}  box rounded`}>
          <div className="header">{this.props.plan}</div>
          <div className="content">
            {this.props.children}
            <div className="feature">
              <div className="price">${this.props.price}</div>
              <Link
                to={`/register?plan=${this.props.plan}`}
                className={`link ${this.props.color} outline`}
                onClick={() => Clickety.recordGoal(this.goal_id(this.props.plan))}
              >
                Choose
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Plan;
