/**
 * This has it's won authentication because it needs it's own layout.
 */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AdminLayout from './admin/layouts/admin-layout';
import SignIn from './admin/auth/sign-in';
import Dashboard from './admin/dashboard';
import Admins from './admin/admins';
import Users from './admin/users/';
import Profile from './admin/users/profile';
import NotFound from './not-found';
import ForgotPassword from './admin/auth/forgot-password';
import ResetPassword from './admin/auth/reset-password';
import UnauthenticatedRoute from './auth/unauthenticated-routes';

const createRoutes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute exact path="/admin/reset-password/:id" props={childProps} component={ResetPassword} />
    <UnauthenticatedRoute exact path="/admin/forgot-password" props={childProps} component={ForgotPassword} />
    <UnauthenticatedRoute exact path="/admin/sign-in" props={childProps} component={SignIn} />
    <AdminRoute path="/admin/users" props={childProps} component={Users} />
    <AdminRoute path="/admin/profile/:id" props={childProps} component={Profile} />
    <AdminRoute path="/admin/team" props={childProps} component={Admins} />
    <AdminRoute path="/admin/dashboard" props={childProps} component={Dashboard} />
    <Redirect from="/admin" to="/admin/dashboard" />
    <Route component={NotFound} />
  </Switch>
);

const AdminRoute = ({ component: C, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        cProps.isAuthenticated ? (
          <AdminLayout signOut={cProps.signOut}>
            <C {...props} {...cProps} />
          </AdminLayout>
        ) : (
          <Redirect to={`${cProps.signIn}?redirect=${props.location.pathname}${props.location.search}`} />
        )
      }
    />
  );
};

export default createRoutes;
