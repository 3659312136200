import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button } from 'antd';
import Network from '../services/network-requests';
import openNotificationWithIcon from '../../utilities/notification';

const FormItem = Form.Item;

function EditProfile(props) {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.form.getFieldValue('firstName') === undefined) {
      var p = props.profile;
      props.form.setFieldsValue({
        firstName: p.firstName,
        lastName: p.lastName,
        email: p.email,
      });
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFields((err, values) => {
      if (!err) {
        let p = {
          firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          email: values.email,
        };
        p._id = props.profile._id;
        updateUser(p);
      } else {
        setLoading(false);
      }
    });
  };

  const updateUser = async p => {
    const res = await Network.put('/admin-profile', p);
    if (res.status === 404) {
      openNotificationWithIcon('error', 'Error', res.data.error);
    } else if (res.status === 201 || res.status === 200) {
      openNotificationWithIcon('success', 'Success', 'User updated.');
      props.drawerAction(false);
      props.fetchData();
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col sm={24} md={8}>
          <FormItem label="First name">
            {getFieldDecorator('firstName', {
              validateTrigger: 'onBlur',
              rules: [{ required: true, message: 'Please enter your first name.' }],
            })(<Input placeholder="First Name" />)}
          </FormItem>
        </Col>
        <Col sm={24} md={8}>
          <FormItem label="Last Name">
            {getFieldDecorator('lastName', {
              validateTrigger: 'onBlur',
              rules: [{ required: true, message: 'Please enter your last name.' }],
            })(<Input placeholder="Last Name" />)}
          </FormItem>
        </Col>

        <Col sm={24} md={8}>
          <FormItem label="Email">
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  type: 'email',
                  message: 'Please enter a valid email.',
                },
                {
                  required: true,
                  message: 'Please enter a valid email.',
                },
              ],
            })(<Input placeholder="Email" />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button loading={loading} htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Form.create()(EditProfile);
