import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import './admin.css';
import Logo from '../../assets/logo.png';
import AdminUser from './admin-avatar';
import { Link } from 'react-router-dom';
const { Header, Sider, Content } = Layout;

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const key = window.location.pathname;
    return (
      <Layout className="admin-layout">
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div id="admin-logo">
            <img src={Logo} alt="admin logo" />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            onOpenChange={this.handleOpenChange}
            selectedKeys={[key]}
          >
            <Menu.Item key="/admin/dashboard">
              <Link to="/admin/dashboard">
                <Icon type="dashboard" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/team">
              <Link to="/admin/team">
                <Icon type="team" />
                <span>Admins</span>
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => this.props.signOut()}>
              <Icon type="logout" />
              <span>Sign Out</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header className="global-header">
            <Icon className="trigger" type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />
            <AdminUser />
          </Header>
          <Content id="main-content">{this.props.children}</Content>
        </Layout>
      </Layout>
    );
  }
}
export default AdminDashboard;
