import React, { Component } from 'react';
import { Form } from 'antd';
import Testomonies from '../testimony';
import './auth.css';

class AuthWrapper extends Component {
  componentDidUpdate = () => {
    localStorage.clear();
  };
  render() {
    return (
      <div className="flex-auth">
        <Testomonies />
        {this.props.children}
      </div>
    );
  }
}

export default Form.create()(AuthWrapper);
