import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, obj) => (
      <Link to={`/admin/profile/${obj._id}`}>
        {obj.firstName} {obj.lastName}
      </Link>
    ),
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
  },
];

const UserTable = function(props) {
  return <Table rowKey={record => record._id} dataSource={props.users} columns={columns} />;
};
export default UserTable;
