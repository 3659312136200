import React, { Component } from 'react';
import { Form, Row, Button, Radio, Alert, Modal } from 'antd';
import { CardElement, injectStripe } from 'react-stripe-elements';
import PRICES from '../constants';
import Clickety from '../clickety/clickety_post';
import Network from '../services/network';
import './upgrade.css';
import openNotificationWithIcon from '../utilities/notification';
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      firstName: '',
      lastName: '',
      planChosen: null,
    };
  }

  componentDidMount = () => {
    this.fetchProfile();
    this.props.form.setFieldsValue({ plan: 'deluxe' });
  };

  fetchProfile = () => {
    var id = this.state.id;
    Network.get('/profile', this.setUpProfile, id);
  };

  setUpProfile = res => {
    if (res && res.status === 200) {
      const profile = res.data.user;
      this.setState({ firstName: profile.firstName, lastName: this.profile.lastName });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var upgrade = {
          plan: values.plan,
        };

        this.props.stripe
          .createToken({ name: `${this.state.firstName} ${this.state.lastName}` })
          .then(({ token }) => {
            upgrade.stripeToken = token.id;
            this.loadingTrue();
            Network.post('/purchase-plan', upgrade, this.networkResponse);
          })
          .catch(e => {
            this.loadingFalse();
            this.setState({
              error: true,
              serverErrors: 'There was an error charging your card. Please try again.',
            });
          });
      } else {
        this.loadingFalse();
      }
    });
  };

  // network stuff
  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  closeAlert = () => {
    this.setState({ error: false, success: false });
  };

  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status === 500) {
        this.setState({ error: true, serverErrors: res.data.error });
      } else if (res.status === 422) {
        this.setState({ error: true, serverErrors: res.data.error });
      } else if (res.status === 201 || res.status === 200) {
        let purchaseInfo = this.priceAndGoalPerPlan(res.data.success);
        Clickety.recordConversion(purchaseInfo.price, purchaseInfo.goalId);

        openNotificationWithIcon('success', 'Success', 'Transaction complete.');
        this.props.reloadContracts();
      }
    }
  };

  priceAndGoalPerPlan = plan => {
    if (plan === 'starter') {
      return { price: PRICES.STARTER.amount, goalId: process.env.REACT_APP_UPGRADE_STARTER };
    } else if (plan === 'deluxe') {
      return { price: PRICES.DELUXE.amount, goalId: process.env.REACT_APP_UPGRADE_DELUXE };
    } else if (plan === 'multi-dog') {
      return { price: PRICES.UNLIMITED.amount, goalId: process.env.REACT_APP_UPGRADE_UNLIMITED };
    }
  };
  completePurchase = () => {
    this.props.form.summit();
  };

  title = () => {
    var id = document.getElementsByClassName('ant-modal-title');
    if (id.length === 1) {
      id[0].innerHTML = `You've reached your limit on the <b>${
        this.props.plan
      }</b> plan. Purchase another plan to add more pets!`;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    this.title();
    return (
      <Modal
        visible={this.props.visible}
        title={' '}
        onCancel={this.props.handleCancel}
        className="upgrade-modal"
        closable={false}
        footer={
          <Button className="link lg blue darker" id="complete-purchase-btn" onClick={this.handleSubmit}>
            Complete Purchase
          </Button>
        }
      >
        <div className="closer ss-delete" alt="closer" onClick={this.props.handleCancel} />
        <Form onSubmit={this.handleSubmit} id="upgrade-form">
          <Row>
            <FormItem>
              {getFieldDecorator('plan', {
                rules: [{ required: true, message: 'Please choose a plan' }],
              })(
                <RadioGroup>
                  <Radio value={PRICES.STARTER.planName} style={{ display: 'block' }}>
                    <span style={{ textTransform: 'capitalize' }}>{PRICES.STARTER.planName}</span> -{' '}
                    {PRICES.STARTER.description} for <span className="price-bold">${PRICES.STARTER.amount}</span>
                  </Radio>
                  <Radio value={PRICES.DELUXE.planName} style={{ display: 'block' }}>
                    <span style={{ textTransform: 'capitalize' }}>{PRICES.DELUXE.planName}</span> -{' '}
                    {PRICES.DELUXE.description} for <span className="price-bold">${PRICES.DELUXE.amount}</span>
                  </Radio>
                  <Radio value={PRICES.UNLIMITED.planName} style={{ display: 'block' }}>
                    <span style={{ textTransform: 'capitalize' }}>{PRICES.UNLIMITED.planName}</span> -{' '}
                    {PRICES.UNLIMITED.description} for <span className="price-bold">${PRICES.UNLIMITED.amount}</span>
                  </Radio>
                </RadioGroup>
              )}
            </FormItem>
          </Row>

          <CardElement />

          {this.state.success && (
            <Alert message={this.state.serverSuccess} type="success" closable afterClose={this.closeAlert} showIcon />
          )}
          {this.state.error && (
            <Alert message={this.state.serverErrors} type="error" closable afterClose={this.closeAlert} showIcon />
          )}
        </Form>
      </Modal>
    );
  }
}
export default injectStripe(Form.create()(Upgrade));
