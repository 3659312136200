import React, { Component } from 'react';
import './pet-info.css';
class PetInfo extends Component {
  render() {
    let contract = this.props.contract;
    let NA = 'N/A'; // not available - this could change
    return (
      <div>
        <div className="pet-info">
          <h2 className="pet-info-header">Pet Identification</h2>
          {contract.image ? (
            <div className="photo">
              <img src={contract.image} alt={contract.petName} />
            </div>
          ) : (
            ''
          )}
          <div id="pet-data">
            <div className="pet-row">
              <div className="pet-col">
                <h2>Name</h2>
                <p>{contract.petName}</p>
              </div>
              <div className="pet-col">
                <h2>Species</h2>
                <p>{contract.species}</p>
              </div>
            </div>
            <div className="pet-row">
              <div className="pet-col">
                <h2>Chip ID</h2>
                <p>{contract.chipId ? contract.chipId : NA}</p>
              </div>
              <div className="pet-col">
                <h2>Breed(s)</h2>
                <p>{contract.breed}</p>
              </div>
            </div>
            <div className="pet-row">
              <div className="pet-col">
                <h2>Unique Markings</h2>
                <p>{contract.uniqueMarkings ? contract.uniqueMarkings : NA}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PetInfo;
