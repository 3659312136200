import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'antd';
import Network from '../services/network-requests';
import openNotificationWithIcon from '../../utilities/notification';
import TextArea from 'antd/lib/input/TextArea';
const FormItem = Form.Item;

function NoteForm(props) {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({ data: [] });

  useEffect(() => {
    if (props.note !== null) {
      props.form.setFieldsValue({
        note: props.note.note,
        userId: props.note.id,
      });
    } else {
      props.form.resetFields();
    }
  }, [props.note]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Network.get('/current-admin');
    setAdmin(result.data.admin);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFields(async (err, values) => {
      if (!err) {
        // Edit note
        if (props.note) {
          var note = props.note;
          note.note = values.note;
          const res = await Network.put(`/admin-notes`, note);
          requestResults(res, 'Note updated.');
        } else {
          // new note
          let note = {
            note: values.note,
            userId: props.id,
            recordedBy: `${admin.firstName} ${admin.lastName}`,
            created: Date.now(),
          };
          const res = await Network.post(`/admin-notes`, note);
          requestResults(res, 'Note created.');
        }
      } else {
        setLoading(false);
      }
    });
  };

  const requestResults = (res, msg) => {
    if (res.status === 404) {
      openNotificationWithIcon('error', 'Error', res.data.error);
    } else if (res.status === 201 || res.status === 200) {
      openNotificationWithIcon('success', 'Success', msg);
      props.fetchData();
      props.drawerAction();
      props.form.resetFields();
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col>
          <FormItem label="Note">
            {getFieldDecorator('note', {
              validateTrigger: 'onBlur',
              rules: [{ required: true, message: "Yeah, we're going to need a note." }],
            })(<TextArea />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button loading={loading} htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Form.create()(NoteForm);
