import React, { useState, useEffect } from 'react';
import Network from './services/network-requests';
import UserTable from './users/user-table';

const AdminDashboard = function(props) {
  const [data, setUsers] = useState({ data: [] });
  useEffect(() => {
    const fetchData = async () => {
      const result = await Network.get('/admin-users');
      setUsers(result.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <UserTable users={data.users} />
    </div>
  );
};
export default AdminDashboard;
