import React, { useState, useEffect } from 'react';
import Network from '../services/network-requests';
import { Drawer, Icon, Row, Col, Card } from 'antd';
import EditProfile from './form';
import NotesList from './notes-list';
import Plan from './plan';
import capitalize from '../../utilities/capitalize';
import SendPassword from './send-password';
import Contracts from './contracts';

export default function Profile(props) {
  const id = props.match.params.id;
  const [user, setUser] = useState({ data: [] });
  const [visible, setDrawer] = useState(false);
  const [title, setTitle] = useState('');
  const [userFrom, setUserForm] = useState(true);
  const [plan, setPlan] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await Network.get('/admin-profile', id);
    setUser(result.data.user);
  };

  const drawerAction = () => {
    setDrawer(!visible);
  };

  const previousPlans = pp => {
    if (pp && pp.length) {
      return pp.map(p => {
        return (
          <div key={p._id}>
            <a href="#" onClick={() => showPlan(p)}>
              {capitalize(p.plan)}
            </a>
          </div>
        );
      });
    } else {
      return 'No previous plans.';
    }
  };

  const formItemLayout = {
    sm: { span: 24 },
    md: { span: 12 },
  };

  const viewing = () => {
    if (userFrom) {
      return <EditProfile profile={user} drawerAction={drawerAction} fetchData={fetchData} />;
    } else {
      return <Plan plan={plan} />;
    }
  };

  const showUserForm = () => {
    setUserForm(true);
    setTitle(`Edit ${user.firstName} ${user.lastName}`);
    setDrawer(!visible);
  };

  const showPlan = plan => {
    setPlan(plan);
    setUserForm(false);
    setTitle(`Plan`);
    setDrawer(!visible);
  };

  return (
    <div id="profile">
      <Drawer
        title={title}
        placement="right"
        closable={true}
        onClose={() => setDrawer(false)}
        visible={visible}
        width="60%"
      >
        {viewing()}
      </Drawer>

      {user ? (
        <Row gutter={16}>
          <Col {...formItemLayout} style={{ marginBottom: '20px' }}>
            <Card
              title={`${user.firstName} ${user.lastName} (${user.email})`}
              extra={
                <a href="#" onClick={() => showUserForm()}>
                  <Icon type="edit" />
                </a>
              }
            >
              <Row>
                <Col span={10}>
                  <span>Current Plan: </span>
                </Col>
                <Col span={14}>
                  <a href="#" onClick={() => showPlan({ plan: user.plan, chargeId: user.chargeId })}>
                    {capitalize(user.plan)}
                  </a>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col span={10}>
                  <span>Previous Plans: </span>
                </Col>
                <Col span={14}>{previousPlans(user.previousPlans)}</Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={10}>
                  <span>Reset Password: </span>
                </Col>
                <Col span={14}>
                  <SendPassword email={user.email} />
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={10}>
                  <span>Contracts: </span>
                </Col>
                <Col span={14}>
                  <Contracts user={user} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col {...formItemLayout}>
            <Row>
              <NotesList user={user} fetchData={fetchData} />
            </Row>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </div>
  );
}
