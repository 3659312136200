import React from 'react';
import { Drawer, Form, Button, Col, Row, Input, Radio } from 'antd';
import Network from '../services/network-requests';
import openNotificationWithIcon from '../../utilities/notification';

class DrawerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: 'New admin account',
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.admin !== null && this.props.admin !== prevProps.admin) {
      var a = this.props.admin;
      this.setState({ title: `Edit ${a.firstName} ${a.lastName}` });
      this.props.form.setFieldsValue({
        firstName: a.firstName,
        lastName: a.lastName,
        email: a.email,
        role: a.role,
      });
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };

  loadingFalse = () => {
    this.setState({ loading: false });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.loadingTrue();
        let admin = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
        };

        this.adminActions(admin);
      } else {
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  adminActions = async admin => {
    this.loadingTrue();
    var res = null;
    if (this.props.admin) {
      admin._id = this.props.admin._id;
      res = await Network.put('/admins', admin);
    } else {
      res = await Network.post('/admins', admin);
    }
    this.loadingFalse();
    if (res && res.status) {
      if (res.status > 399) {
        console.log(res.data);
        openNotificationWithIcon('error', 'Error', res.data.error);
      } else if (res.status === 201 || res.status === 200) {
        openNotificationWithIcon('success', 'Success', this.props.admin ? 'Admin updated.' : 'Admin Created.');
        this.props.closeDrawerAndRefresh(admin);
        this.resetTitleAndFields();
      }
    }
  };

  resetTitleAndFields = () => {
    setTimeout(() => {
      this.setState({ title: 'New admin account' });
      this.props.form.resetFields();
    }, 500);
  };

  dismiss = () => {
    this.props.drawerAction();
    this.resetTitleAndFields();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer title={this.state.title} width={720} onClose={() => this.dismiss()} visible={this.props.visible}>
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="First Name">
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'First name required' }],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last Name">
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Last name required' }],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Email">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Email required',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Role">
                  {getFieldDecorator('role', {
                    rules: [
                      {
                        required: true,
                        message: 'Please choose a role',
                      },
                    ],
                  })(
                    <Radio.Group>
                      <Radio value="Manager">Manager</Radio>
                      <Radio value="Staff">Staff</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >
              <Button className="link darker" loading={this.state.loading} htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

export default Form.create()(DrawerForm);
