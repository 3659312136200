import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import Close from '../assets/close@2x.png';

class ReviewChanges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: this.props.contract,
    };
  }

  /**
   * Drawer methods
   */
  onClose = () => {
    this.props.closeDrawer();
  };

  render() {
    return (
      <Modal
        closable={false}
        onClose={this.onClose}
        visible={this.props.visible}
        onCancel={this.onClose}
        className="styled-modal"
        footer={[
          <Button
            key="1"
            className="link darker sign-btn"
            loading={this.state.loading}
            htmlType="submit"
            onClick={this.onClose}
          >
            Close
          </Button>,
        ]}
      >
        <img src={Close} className="edit-closer" alt="closer" onClick={this.onClose} />
        <h2 className="drawer-header">Review Changes</h2>
        <div id="requested-changes">Requested Changes: {this.state.contract.changes}</div>
      </Modal>
    );
  }
}
export default ReviewChanges;
