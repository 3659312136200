import React, { Component } from 'react';
import { Avatar, Divider } from 'antd';
import facebook from '../assets/facebook.png';
import './footer.css';

class SiteFooter extends Component {
  render() {
    return (
      <div id="footer">
        <div className="container">
          <a href="https://www.facebook.com/petprenup" target="_blank" rel="noopener noreferrer">
            <Avatar src={facebook} />
          </a>
          <div className="footer-links">
            <a href="mailto:support@petprenup.com">Support</a>
            <Divider type="vertical" />
            <a href="/privacy-policy" target="_blank">
              Privacy
            </a>
            <Divider type="vertical" />
            <a href="/terms-and-conditions" target="_blank">
              Terms
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default SiteFooter;
