import React, { Component } from 'react';
import { Icon, Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import Croppie from 'croppie';
import openNotificationWithIcon from '../utilities/notification';
import './image-upload.css';

var croppie;

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      currentImageUrl: this.props.currentImageUrl || null,
      visible: false,
    };
  }

  removeImage = () => {
    this.hideCropContainer();
    this.props.callback(null);
    this.setState({ currentImageUrl: null });
  };

  /**
   * uploader start
   */
  uploaderClick = () => {
    document.getElementById('new-image').click();
  };

  /**
   * Image upload
   */
  previewImage = input => {
    var files = input.target.files;
    if (files && files[0]) {
      if (/^image\//.test(files[0].type)) {
        var reader = new FileReader();
        reader.onload = e => {
          this.setState({ currentImageUrl: e.target.result });
          this.props.callback(e.target.result);
          this.cropImage();
        };
        reader.readAsDataURL(files[0]);
      } else {
        openNotificationWithIcon('error', 'Error', 'Wrong file type.');
      }
    }
  };

  startCropping = () => {
    var el = document.getElementById('crop-area');
    var img = document.getElementById('pet-image');

    croppie = new Croppie(el, {
      viewport: { width: 200, height: 200, type: 'circle' },
      boundary: { width: 200, height: 200 },
      enableExif: true,
    });
    croppie.bind({
      url: img.src,
    });

    this.hideSpinner();
  };

  /**
   * crop image
   */
  cropImage = () => {
    this.setState({ visible: true }, () => {
      if (croppie) {
        croppie.destroy();
      }
      setTimeout(() => {
        this.startCropping();
      }, 500);
    });
  };

  doneCropping = () => {
    if (croppie) {
      var that = this;
      croppie.result({ type: 'base64', size: 'original', format: 'png', quality: 1 }).then(function(blob) {
        that.setState({ currentImageUrl: blob });
        that.props.callback(blob);
      });
    }
    this.hideCropContainer();
  };

  cancelCropping = () => {
    this.hideCropContainer();
  };

  showCropContainer = () => {
    document.getElementById('crop-wrap').classList.add('show');
    document.getElementById('upload-container').classList.add('hide');
  };

  hideCropContainer = () => {
    document.getElementById('crop-wrap').classList.remove('show');
    document.getElementById('upload-container').classList.remove('hide');
    this.showSpinner();
  };

  showSpinner = () => {
    // in case they crop again, remove hide from spinner
    var spinner = document.getElementById('crop-spinner');
    setTimeout(() => {
      if (spinner) {
        spinner.classList.remove('hide');
      }
    }, 1000);
  };

  hideSpinner = () => {
    // in case they crop again, remove hide from spinner
    var spinner = document.getElementById('crop-spinner');
    spinner.classList.add('hide');
  };

  renderImage = () => {
    if (this.state.currentImageUrl) {
      return (
        <div id="upload-container">
          <div className="pet-image-container">
            <img src={this.state.currentImageUrl} alt="pet" id="pet-image" />
          </div>
          <div className="upload-text">
            <Link to="#!" onClick={this.removeImage} className="remove-img-link">
              Delete
            </Link>
            <Link to="#!" onClick={this.cropImage} className="crop-img-link link-btn">
              Crop
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div id="upload-container">
          <input type="file" id="new-image" onChange={this.previewImage} />
          <div id="uploader" onClick={this.uploaderClick}>
            <div className="drag-icon">
              <Icon type="picture" theme="filled" style={{ color: 'white' }} />
            </div>
          </div>

          <div className="upload-text">
            <div>Add a photo of your pet!</div>
            <div>Click the icon to upload. </div>
            <div>*required</div>
          </div>
        </div>
      );
    }
  };

  handleOk = () => {
    this.setState({ visible: false });
    this.doneCropping();
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.cancelCropping();
  };

  render() {
    return (
      <div>
        <div id="crop-wrap">
          <Modal
            width={300}
            closable={false}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            className="image-modal"
          >
            <div id="crop-container">
              <div id="crop-area" />
              <div id="crop-spinner">
                <Spin size="large" /> Loading...
              </div>
            </div>
          </Modal>
        </div>

        {this.renderImage()}
      </div>
    );
  }
}
export default ImageUpload;
