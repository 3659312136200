const checkDate = date => {
  var newValue = date.replace(/\D/g, '');
  var valid = newValue.length === 8 ? true : false;
  var month = newValue.substr(0, 2);
  var day = newValue.substr(2, 2);
  var year = newValue.substr(4);
  var thisYear = new Date().getFullYear();

  var monthNum = parseInt(month);
  var dayNum = parseInt(day);
  var yearNum = parseInt(year);
  if (valid && (monthNum > 0 && monthNum <= 12 && dayNum > 0 && dayNum < 31 && yearNum > 1900 && yearNum < thisYear)) {
    return {success: month + '/' + day + '/' + year};
  } else {
    return {error: 'Incorrect date format'};
  }
};

export default checkDate;
