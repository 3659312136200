import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard/';
import Profile from './profile';
import Plans from './plans';
import Home from './home/';
import Contract from './contracts/show';
import ContractReview from './contracts/review';
import ContractNew from './contracts/new';
import ContractEdit from './contracts/edit';
import ContractSign from './contracts/sign';
import ContactForm from './contact/form';
import RequestSignature from './contracts/request-signature';
import Register from './auth/register';
import Signin from './auth/signin';
import ForgotPassword from './auth/forgot-passowrd';
import Header from './header';
import Footer from './footer';
import ResetPassword from './auth/reset-password';
import TermsAndConditions from './legal/terms-and-conditions';
import PrivacyPolicy from './legal/privacy-policy';
import NotFound from './not-found';
import AuthenticatedRoute from './auth/authenticated-routes';
import UnauthenticatedRoute from './auth/unauthenticated-routes';
import './App.css';
import './print.css';
import GA from './utilities/google-analytics';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  componentDidMount = () => {
    var token = localStorage.getItem('token');
    let auth = token === null ? false : true;
    this.setState({ isAuthenticated: auth });
    this.addClassToBodyIfLoggedIn();
  };

  componentDidUpdate = () => {
    this.addClassToBodyIfLoggedIn();
  };

  addClassToBodyIfLoggedIn = () => {
    var token = localStorage.getItem('token');
    var body = document.body;
    if (token) {
      body.classList.add('private');
    } else {
      body.classList.remove('private');
    }
  };

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  signOut = () => {
    this.userHasAuthenticated(false);
    localStorage.clear();
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      signIn: '/sign-in',
      defaultRoute: '/dashboard',
    };
    return (
      <Fragment>
        <Header isAuthenticated={childProps.isAuthenticated} signOut={this.signOut} />
        <div className="page-wrap">
          {GA.init() && <GA.RouteTracker />}
          <Switch>
            <AuthenticatedRoute exact path="/dashboard" props={childProps} component={Dashboard} />
            <AuthenticatedRoute exact path="/profile" props={childProps} component={Profile} />
            <AuthenticatedRoute exact path="/contracts/new" props={childProps} component={ContractNew} />
            <AuthenticatedRoute exact path="/contracts/:id" props={childProps} component={Contract} />
            <AuthenticatedRoute exact path="/contracts/edit/:id" props={childProps} component={ContractEdit} />
            <AuthenticatedRoute exact path="/contracts/sign/:id" props={childProps} component={ContractSign} />
            <AuthenticatedRoute
              exact
              path="/contracts/request-signature/:id"
              props={childProps}
              component={RequestSignature}
            />

            <Route exact path="/contracts/review/:id" props={childProps} component={ContractReview} />
            <UnauthenticatedRoute exact path="/sign-in" props={childProps} component={Signin} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <UnauthenticatedRoute exact path="/reset-password/:id" props={childProps} component={ResetPassword} />
            <UnauthenticatedRoute exact path="/register" props={childProps} component={Register} />
            <Route exact path="/plans" component={Plans} />
            <Route exact path="/contact" component={ContactForm} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default App;
// export default App;
