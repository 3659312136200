import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Alert, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import Network from '../services/network';
import { CardElement, injectStripe } from 'react-stripe-elements';
import PRICES from '../constants';
import Clickety from '../clickety/clickety_post';
import AuthWrapper from './auth-wrapper';
import './auth.css';

const FormItem = Form.Item;

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      successRegisteringAccount: false,
      errorRegisteringAccount: false,
      serverErrors: '',
      serverSuccess: '',
      toDashboard: false,
      plan: null,
      stripToken: null,
      badUrl: false,
      passwordComplexity: true,
      passwordStatus: null,
      passwordFeedback: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var plan = this.getParameterByName('plan');
    this.setState({ plan: plan });
    document.body.classList.add('session');
  }

  componentWillUpdate() {
    if (
      this.props.location.search !== '?plan=starter' &&
      this.props.location.search !== '?plan=deluxe' &&
      this.props.location.search !== '?plan=unlimited'
    ) {
      // url doesn't contain a plan
      if (this.state.badUrl === false) {
        this.setState({ badUrl: true });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('session');
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var account = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          plan: this.state.plan,
        };
        //
        this.props.stripe
          .createToken({ name: `${account.firstName} ${account.lastName}` })
          .then(({ token }) => {
            account.stripeToken = token.id;
            this.loadingTrue();
            Network.post('/signup', account, this.networkResponse);
          })
          .catch(e => {
            this.loadingFalse();
            this.setState({
              errorRegisteringAccount: true,
              serverErrors: 'There was an error charging your card. Please try again.',
            });
          });
      }
    });
  };

  // network stuff
  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status === 500) {
        this.setState({ errorRegisteringAccount: true, serverErrors: res.data.error });
      } else if (res.status === 422) {
        this.setState({ errorRegisteringAccount: true, serverErrors: res.data.error });
      } else if (res.status === 201 || res.status === 200) {
        // save to local storage
        localStorage.setItem('token', res.data.token);
        this.props.userHasAuthenticated(true);
        let purchaseInfo = this.priceAndGoalPerPlan();
        Clickety.recordConversion(purchaseInfo.price, purchaseInfo.goalId);
      }
    }
  };

  closeAlert = () => {
    this.setState({ successRegisteringAccount: false, errorRegisteringAccount: false });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Make sure both passwords are the same.');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  priceAndGoalPerPlan = () => {
    var plan = this.state.plan;
    if (plan === 'starter') {
      return { price: PRICES.STARTER.amount, goalId: process.env.REACT_APP_UPGRADE_STARTER };
    } else if (plan === 'deluxe') {
      return { price: PRICES.DELUXE.amount, goalId: process.env.REACT_APP_UPGRADE_DELUXE };
    } else if (plan === 'multi-dog') {
      return { price: PRICES.UNLIMITED.amount, goalId: process.env.REACT_APP_UPGRADE_UNLIMITED };
    }
  };

  planInfo = () => {
    var plan = this.state.plan;
    if (plan === 'starter') {
      return (
        <div>
          Today’s charge will be <span className="plan-amount">${PRICES.STARTER.amount}</span> for{' '}
          <span className="plan-name">{PRICES.STARTER.planName}.</span>
        </div>
      );
    } else if (plan === 'deluxe') {
      return (
        <div>
          Today’s charge will be <span className="plan-amount">${PRICES.DELUXE.amount}</span> for{' '}
          <span className="plan-name">{PRICES.DELUXE.planName}.</span>
        </div>
      );
    } else if (plan === 'multi-dog') {
      return (
        <div>
          Today’s charge will be <span className="plan-amount">${PRICES.UNLIMITED.amount}</span> for{' '}
          <span className="plan-name">{PRICES.UNLIMITED.planName}</span>
        </div>
      );
    }
  };

  isValidComplexity = val => {
    if (val.length === 0) {
      return true;
    }
    var complexity = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    // if val has no length
    if (val.match(complexity)) {
      return true;
    }
    return false;
  };

  checkPassword = e => {
    var val = e.target.value;
    var status = null;
    var feedBack = '';
    if (val.length === 0 || val === '') {
      this.setState({ passwordStatus: 'error', passwordFeedback: 'Please enter a password', passwordComplexity: true });
      return true;
    }

    // if val has no length
    if (val.length < 7) {
      status = 'error';
      feedBack = 'Password too short';
    } else if (!this.isValidComplexity(val)) {
      status = 'error';
      feedBack = 'Type a valid password';
    }

    this.setState({
      passwordStatus: status,
      passwordFeedback: feedBack,
      passwordComplexity: this.isValidComplexity(val),
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <AuthWrapper>
        <div id="registration">
          <Form onSubmit={this.handleSubmit} id="registration-form">
            <h2 className="header-text">Complete signup</h2>
            <p>It’s easy to get started!</p>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem>
                  {getFieldDecorator('firstName', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Please type your first name',
                      },
                    ],
                  })(<Input placeholder="First name" />)}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Please type your last name',
                      },
                    ],
                  })(<Input placeholder="Last name" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <FormItem>
                  {getFieldDecorator('email', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        type: 'email',
                        message: 'Please enter a valid email',
                      },
                      {
                        required: true,
                        message: 'Please enter your email address',
                      },
                    ],
                  })(<Input placeholder="Email" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} lg={12}>
                <FormItem validateStatus={this.state.passwordStatus} help={this.state.passwordFeedback}>
                  <Tooltip
                    placement="topLeft"
                    visible={this.state.passwordComplexity ? false : true}
                    title="8 or more characters, including an uppercase and a special character."
                  />

                  {getFieldDecorator('password', {
                    rules: [
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input type="password" onChange={this.checkPassword} placeholder="Password" />)}
                </FormItem>
              </Col>
              <Col sm={24} lg={12}>
                <FormItem>
                  {getFieldDecorator('confirm', {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input type="password" onBlur={this.handleConfirmBlur} placeholder="Confirm password" />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <FormItem>
                <CardElement placeholder="Card Number" />
              </FormItem>
            </Row>
            <Row>
              <FormItem>
                {getFieldDecorator('agreement', {
                  rules: [
                    {
                      required: true,
                      message: 'Please agree to our terms of service',
                    },
                  ],
                  valuePropName: 'checked',
                })(
                  <Checkbox>
                    Agree to <a href="/terms-and-conditions">terms and conditions</a>
                  </Checkbox>
                )}
              </FormItem>
            </Row>
            <Row>
              <Col span={24}>
                {this.state.successRegisteringAccount && (
                  <Alert
                    message={this.state.serverSuccess}
                    type="success"
                    closable
                    afterClose={this.closeAlert}
                    showIcon
                  />
                )}
                {this.state.errorRegisteringAccount && (
                  <Alert
                    message={this.state.serverErrors}
                    type="error"
                    closable
                    afterClose={this.closeAlert}
                    showIcon
                  />
                )}
              </Col>
            </Row>
            <div id="plan-info">{this.planInfo()}</div>
            <FormItem>
              <Button className="link lg blue" id="complete-purchase" loading={this.state.loading} htmlType="submit">
                Complete Purchase
              </Button>
            </FormItem>

            <div className="sign-in">
              Already have an account? <Link to="/sign-in">Sign In</Link>
            </div>
          </Form>
        </div>
      </AuthWrapper>
    );
  }
}

export default injectStripe(Form.create()(RegistrationForm));
