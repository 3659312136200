import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Divider, Icon } from 'antd';
import Network from '../services/network';
import openNotificationWithIcon from '../utilities/notification';
const FormItem = Form.Item;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    var p = this.props.profile;

    this.props.form.setFieldsValue({
      firstName: p.firstName,
      lastName: p.lastName,
      email: p.email,
    });

    this.getCharge(p);
  };

  getCharge = p => {
    Network.get('/charge', this.setCharge, p.chargeId);
  };

  setCharge = res => {
    if (res && res.status) {
      if (res.status === 404) {
      } else if (res.status === 201 || res.status === 200) {
        this.setState({ charge: res.data.charge });
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let p = {
          firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          email: values.email,
        };

        if (values.password !== undefined && values.password !== '') {
          if (values.newPassword === undefined || values.confirmNewPassword === undefined) {
            openNotificationWithIcon('error', 'Error', 'Please check passwords.');
            return;
          }

          if (values.newPassword !== values.confirmNewPassword) {
            openNotificationWithIcon('error', 'Error', "Passwords don't match.");
            return;
          }
          p.password = values.password;
          p.newPassword = values.newPassword;
        }

        this.loadingTrue();
        p._id = this.props.profile._id;
        Network.put('/profile', p, this.networkResponse);
      } else {
        openNotificationWithIcon('error', 'Error', 'Error updating profile. Please review form.');
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res && res.status) {
      if (res.status === 404) {
        openNotificationWithIcon('error', 'Error', res.data.error);
      } else if (res.status === 201 || res.status === 200) {
        openNotificationWithIcon('success', 'Success', 'Information Updated.');
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  /**
   * plan data
   */
  showPlanData = () => {
    if (this.props.profile && this.props.profile.plan) {
      let p = this.props.profile;
      return (
        <div id="plan-data">
          <div>
            Plan Type: <span className="emp">{p.plan}</span>
          </div>
          <div>
            Amount Billed: <span className="emp">{this.chargeData()}</span>
          </div>
        </div>
      );
    }
    return '';
  };

  /**
   * charge data
   */
  chargeData = () => {
    if (this.state.charge) {
      let c = this.state.charge;
      return `$${c.amount * 0.01} on ${c.source.brand} *${c.source.last4}`;
    } else {
      return <Icon type="loading" />;
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Make sure both passwords are the same.');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} id="edit-profile">
        <h2>Settings</h2>
        <Divider />
        <Row gutter={16}>
          <Col sm={24} md={8}>
            <FormItem label="First name">
              {getFieldDecorator('firstName', {
                validateTrigger: 'onBlur',
                rules: [{ required: true, message: 'Please enter your first name.' }],
              })(<Input placeholder="First Name" />)}
            </FormItem>
          </Col>
          <Col sm={24} md={8}>
            <FormItem label="Last Name">
              {getFieldDecorator('lastName', {
                validateTrigger: 'onBlur',
                rules: [{ required: true, message: 'Please enter your last name.' }],
              })(<Input placeholder="Last Name" />)}
            </FormItem>
          </Col>

          <Col sm={24} md={8}>
            <FormItem label="Email">
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email.',
                  },
                  {
                    required: true,
                    message: 'Please enter a valid email.',
                  },
                ],
              })(<Input placeholder="Email" />)}
            </FormItem>
          </Col>

          <Col sm={24} md={12}>
            <FormItem label="Current password">
              {getFieldDecorator('password', {})(<Input type="password" placeholder="Password" />)}
              <span className="form-info">Leave blank to keep current password.</span>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={12}>
            <FormItem label="New password">
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input type="password" placeholder="New password" />)}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={12}>
            <FormItem label="Confirm new password">
              {getFieldDecorator('confirmNewPassword', {
                rules: [
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input type="password" placeholder="Confirm new password" />)}
            </FormItem>
          </Col>
        </Row>
        {this.showPlanData()}
        <Row gutter={16}>
          <Button className="link aqua sign-btn" loading={this.state.loading} htmlType="submit">
            Save
          </Button>
        </Row>
      </Form>
    );
  }
}
export default Form.create()(EditProfile);
