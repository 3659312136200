import React, { Component } from 'react';
import moment from 'moment';
import Network from '../services/network';
import PawPrint from '../assets/paw-print.svg';
import Logo from '../assets/logo.svg';
import './contracts.css';
import PartnerSignature from './partner-signature';
import RequestChanges from './request-changes';
import ContractView from './contract-view';
import ContractHeader from './contract-header';

class ShowContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      you: 'you',
      pet: 'your pet',
      partner: 'your partner',
      visible: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('review');
    if (this.props.match.params.id) {
      var id = this.props.match.params.id;
      if (id) {
        this.setState({ id: id }, () => {
          this.fetchContract();
        });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('review');
  }

  fetchContract = () => {
    var id = this.state.id;
    Network.get('/contracts/review', this.setUpContract, id);
  };

  setUpContract = res => {
    if (res && res.status === 200) {
      const contract = res.data.contract;
      this.setState({
        contract,
        partner: `${contract.partnerFirstName} ${contract.partnerLastName}`,
        you: `${contract.firstName} ${contract.lastName}`,
        pet: `${contract.petName}`,
      });
    }
  };

  modalFooter = () => {
    if (this.state.contract) {
      var contract = this.state.contract;
      return (
        <div className="contract-footer flex">
          {this.makeChanges()}
          {this.buttonBasedOnStatus(contract.statusCode)}
        </div>
      );
    }
  };

  makeChanges = () => {
    if (this.state.contract && this.state.contract.statusCode < 5) {
      return (
        <div onClick={() => this.requestChanges()} className="faux-link">
          Request changes
        </div>
      );
    } else {
      return <div className="status-signed">Status: Signed</div>;
    }
  };

  buttonBasedOnStatus = statusCode => {
    if (statusCode === 3) {
      return (
        <div onClick={this.readyToSign} className="link darker float-right pointer">
          I'm ready to sign
        </div>
      );
    }

    if (statusCode === 4) {
      return <div className="">Awaiting Changes</div>;
    }
    if (statusCode === 5) {
      return (
        <div onClick={() => window.print()} className="link darker contract-footer-btn pointer">
          Save and print
        </div>
      );
    }
  };

  /**
   * drawer methods
   */
  requestChanges = () => {
    this.setState({ drawerContent: 'requestChanges', visible: true, title: 'Request Changes' });
  };

  readyToSign = () => {
    this.setState({ drawerContent: 'readyToSign', visible: true, title: 'Ready to sign' });
  };

  /**
   * Drawer content
   */
  drawerContent = () => {
    if (this.state.drawerContent === 'requestChanges') {
      return (
        <RequestChanges
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
          title={this.state.title}
        />
      );
    }

    if (this.state.drawerContent === 'readyToSign') {
      return (
        <PartnerSignature
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
          title={this.state.title}
        />
      );
    }
  };

  /**
   * callbacks for drawer
   */
  closeDrawerAndUpdateView = () => {
    this.setState({ visible: false });
    this.fetchContract();
  };

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  /**
   * signatures
   */
  showSignatures = () => {
    if (this.state.contract && this.state.contract.statusCode > 1) {
      return (
        <div id="signatures">
          <div className="col">
            <div className="signature">
              <img src={this.state.contract.signature} alt="signature" />
            </div>
            <div className="contract-line left">
              <div className="contract-name">
                Name: {this.state.contract.legalFirstName} {this.state.contract.legalLastName}
              </div>
              <div className="contract-date">Date: {moment(this.state.contract.signedAt).format('MM/DD/YYYY')}</div>
            </div>
          </div>
          <div className="col">
            <div className="signature">{this.partnerSig()}</div>
            <div className="contract-line right">
              <div className="contract-name">
                Name: {this.state.contract.partnerLegalFirstName} {this.state.contract.partnerLegalLastName}
              </div>
              <div className="contract-date">
                Date: {moment(this.state.contract.partnerSignedAt).format('MM/DD/YYYY')}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  /**
   * partner sig
   */

  partnerSig = () => {
    if (this.state.contract.partnerSignature) {
      return <img src={this.state.contract.partnerSignature} alt="Partner Signature" />;
    }
  };

  renderHeader = () => {
    if (this.state.contract) {
      return <ContractHeader statusCode={this.state.contract.statusCode} partner={this.state.partner} />;
    } else {
      return 'tst';
    }
  };

  renderBody = () => {
    if (this.state.contract) {
      var contract = this.state.contract;
      return (
        <div className="contract-body">
          <ContractView contract={contract} />
          {this.showSignatures()}
        </div>
      );
    }
  };

  render() {
    return (
      <div id="contract">
        {this.drawerContent()}
        {this.renderHeader()}
        <div className="paper">
          <img src={PawPrint} alt="paw" />
          <img className="float-right contract-logo" src={Logo} alt="logo" />
          <div className="contract-content">{this.renderBody()}</div>
        </div>
        {this.modalFooter()}
      </div>
    );
  }
}
export default ShowContract;
