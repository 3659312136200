import React, { Component } from 'react';
import { Form, Input, Row, Col, Alert, Button } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Network from '../services/network';
import SignatureCanvas from 'react-signature-canvas';
import './contracts.css';

const FormItem = Form.Item;

class Sign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      contract: null,
      loading: false,
      successSaving: false,
      errorSaving: false,
      signatureError: false,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      var id = this.props.match.params.id;
      if (id) {
        this.setState({ id: id }, () => {
          this.fetchContract();
        });
      }
    }
  };

  componentDidUpdate = prevState => {
    if (prevState.signatureError) {
      this.setState({ signatureError: false });
    }
  };

  fetchContract = () => {
    var id = this.state.id;
    Network.get('/contracts', this.setUpContract, id);
  };

  setUpContract = res => {
    if (res.status === 200) {
      const contract = res.data.contract;
      this.setState({
        contract,
      });
    }
  };

  contractFooter = () => {
    if (this.state.contract) {
      return (
        <div className="contract-footer">
          <Link to={`/contracts/${this.state.contract._id}`} className="float-left text-btn">
            Go back
          </Link>
          <Button className="link darker sign-btn" loading={this.state.loading} htmlType="submit">
            Sign Document
          </Button>
        </div>
      );
    }
  };

  // submit
  handleSubmit = e => {
    this.setState({
      signatureError: false,
    });
    e.preventDefault();
    this.loadingTrue();
    this.props.form.validateFields((err, values) => {
      if (!err && this.sigPad.toData().length) {
        let contract = {
          _id: this.state.contract._id,
          signatureFirstName: values.firstName,
          signatureLastName: values.lastName,
          signature: this.sigPad.getTrimmedCanvas().toDataURL(),
        };
        this.loadingTrue();
        contract._id = this.state.contract._id;
        Network.put('/contracts/signature', contract, this.networkResponse);
      } else {
        this.setState({ signatureError: true });
        this.loadingFalse();
      }
    });
  };

  // Network Stuff
  networkResponse = res => {
    this.loadingFalse();
    if (res.status) {
      if (res.status === 404) {
        this.setState({ errorSaving: true });
      } else if (res.status === 201 || res.status === 200) {
        this.setState({ successSaving: true, contract: res.data.contract, saved: true });
      }
    }
  };

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  // signature methods
  sigPad = {};
  clear = e => {
    e.preventDefault();
    this.sigPad.clear();
    this.setState({
      trimmedDataURL: null,
    });
  };

  signatureError = () => {
    if (this.state.signatureError) {
      return (
        <div style={{ color: '#f5222d' }} className="ant-form-explain">
          Signature cannot be blank
        </div>
      );
    }
    return '';
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="contract" className="signature">
        <Form onSubmit={this.handleSubmit}>
          <div className="center header">
            <div className="page-title">Sign and send your contract</div>
            <p>Sign your half of the contract</p>
          </div>

          <div className="paper">
            <div className="form-label">Type your full legal name</div>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <FormItem>
                  {getFieldDecorator('firstName', {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: 'Please input your first name.' }],
                  })(<Input placeholder="First Name" />)}
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: 'Please input your last name.' }],
                  })(<Input placeholder="Last Name" />)}
                </FormItem>
              </Col>
            </Row>

            <Link style={{ marginTop: '15px' }} to="#" className="float-right" onClick={this.clear}>
              Clear
            </Link>
            <div className="form-label">Write your signature in the box below.</div>

            <SignatureCanvas
              penColor="black"
              canvasProps={{ className: 'sigCanvas' }}
              ref={ref => {
                this.sigPad = ref;
              }}
            />
            {this.signatureError()}
            {this.state.successSaving && (
              <div className="alert-wrapper">
                <Redirect push to={`/contracts/request-signature/${this.state.id}`} />;
                <Alert
                  message={`Successfully ${this.state.isEditing ? 'updated' : 'saved'} contract.`}
                  type="success"
                  showIcon
                />
              </div>
            )}
            {this.state.errorSaving && (
              <div className="alert-wrapper">
                <Alert
                  message={`Error ${this.state.isEditing ? 'updating' : 'saving'} contract.`}
                  type="error"
                  showIcon
                />
              </div>
            )}
          </div>
          {this.contractFooter()}
        </Form>
      </div>
    );
  }
}
export default Form.create()(Sign);
