import Axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
const config = {};

/**
 * Log data for dev
 */
config.logData = function(data, isError) {
  if (process.env.REACT_APP_LOG === 'true') {
    if (isError) {
      console.error(data);
    } else {
      console.log(data);
    }
  }
};

/**
 * Post to node to insert api key then post to Clickety tracker
 *
 */
config.post = function(obj) {
  let headers = {};
  let token = localStorage.getItem('token');
  if (localStorage.getItem('token')) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
  }
  Axios.post('/api/clickety-tracker', obj, {
    headers: headers,
  })
    .then(function(response) {
      // just for dev
      config.logData(response.data, false);
    })
    .catch(function(error) {
      // just for dev
      config.logData(error, true);
    });
};

/**
 * Hash for fingerprint
 */
config.hash = components => {
  return Fingerprint2.x64hash128(
    components
      .map(function(pair) {
        return pair.value;
      })
      .join()
  );
};

/**
 * Fingerprint for non-signed in users
 */
config.fingerPrint = function(resolve) {
  Fingerprint2.get(function(components) {
    let hash = config.hash(components);
    resolve(hash);
  });
};

/**
 * Record campaign
 */
config.recordCampaign = function() {
  let promise = new Promise(function(resolve) {
    config.fingerPrint(resolve);
  });

  promise.then(function(fingerPrint) {
    config.gatherData(fingerPrint);
  });

  promise.catch(function(e) {
    console.log(e.message);
  });
};

/**
 * Goal clicked
 */
config.recordGoal = function(goalId) {
  let promise = new Promise(function(resolve) {
    config.fingerPrint(resolve);
  });

  promise.then(function(fingerPrint) {
    let obj = {};
    obj.user_id = fingerPrint;
    obj.completion_goal_id = goalId;
    config.post(obj);
  });

  promise.catch(function(e) {
    console.log(e.message);
  });
};

/**
 * Record conversion
 * This can be recorded with a goal
 * Example: starter goal with starter amount $19.99
 */
config.recordConversion = function(amount, goalId = null) {
  let promise = new Promise(function(resolve) {
    config.fingerPrint(resolve);
  });

  promise.then(function(fingerPrint) {
    let obj = {};
    obj.user_id = fingerPrint;
    obj.conversion_amount = amount;
    if (goalId !== null) {
      obj.completion_goal_id = goalId;
    }
    config.post(obj);
  });

  promise.catch(function(e) {
    console.log(e.message);
  });
};

/**
 * Gather data
 */
config.gatherData = function(fingerPrint) {
  const urlParams = new URLSearchParams(window.location.search);
  let cid = urlParams.get('campaign_id');
  let gid = urlParams.get('completion_goal_id');
  let ca = urlParams.get('conversion_amount');
  let agn = urlParams.get('ad_group_name');
  let an = urlParams.get('ad_name');
  let k = urlParams.get('keyword');
  let obj = {};
  obj.user_id = fingerPrint;

  if (cid) {
    obj.campaign_id = cid;
  }

  if (gid) {
    obj.completion_goal_id = gid;
  }

  if (ca) {
    obj.conversion_amount = ca;
  }

  if (agn) {
    obj.ad_group_name = agn;
  }

  if (an) {
    obj.ad_name = an;
  }

  if (k) {
    obj.keyword = k;
  }

  config.post(obj);
};

export default config;
