import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import moment from 'moment';
import Network from '../services/network';
import PawPrint from '../assets/paw-print.svg';
import Logo from '../assets/logo.svg';
import './contracts.css';
import SignatureForm from './signature-form';
import Edit from './edit';
import RequestSignature from './request-signature';
import ReviewChanges from './review-changes';
import ContractView from './contract-view';
import ContractHeader from './contract-header';
import PPModal from '../modal';
import docClock from '../assets/doc-clock.png';

class ShowContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      originator: 'you',
      pet: 'your pet',
      partner: 'your partner',
      visible: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      var id = this.props.match.params.id;
      if (id) {
        this.setState({ id: id }, () => {
          this.fetchContract();
        });
      }
    }
  }

  fetchContract = () => {
    var id = this.state.id;
    Network.get('/contracts', this.setUpContract, id);
  };

  setUpContract = res => {
    if (res.status === 200) {
      const contract = res.data.contract;
      this.setState({
        contract,
        partner: `${contract.partnerFirstName} ${contract.partnerLastName}`,
        originator: `${contract.firstName} ${contract.lastName}`,
        pet: `${contract.petName}`,
      });
    }
  };

  modalFooter = () => {
    if (this.state.contract) {
      var contract = this.state.contract;
      return (
        <div className="contract-footer flex">
          {this.makeChanges()}
          {this.buttonBasedOnStatus(contract.statusCode)}
        </div>
      );
    }
  };

  buttonBasedOnStatus = statusCode => {
    if (statusCode === 1) {
      return (
        <div onClick={this.editSignature} className="link darker contract-footer-btn pointer">
          Sign Contract
        </div>
      );
    }
    if (statusCode === 2) {
      return (
        <div onClick={this.requestSignature} className="link orange contract-footer-btn pointer">
          Request Signature
        </div>
      );
    }
    if (statusCode === 3) {
      return <div className="link badge-green contract-footer-btn">Awaiting Partner Signature</div>;
    }
    if (statusCode === 4) {
      return (
        <div onClick={this.reviewChanges} className="link red contract-footer-btn pointer">
          Review Requested Changes
        </div>
      );
    }
    if (statusCode === 5) {
      return (
        <div onClick={() => window.print()} className="link darker contract-footer-btn pointer">
          Save and print
        </div>
      );
    }
  };

  editYourSignature = () => {
    if (this.state.contract && this.state.contract.statusCode < 5) {
      return (
        <Link to="#">
          <Icon type="edit" theme="outlined" onClick={() => this.editSignature()} />
        </Link>
      );
    }
  };

  makeChanges = () => {
    if (!this.state.contract || this.state.contract.statusCode === 3) return <div>&nbsp;</div>;
    if (this.state.contract.statusCode < 3 || this.state.contract.statusCode === 4) {
      return (
        <div onClick={() => this.edit()} className="faux-link">
          Make changes
        </div>
      );
    } else {
      return <div className="status-signed">Status: Signed</div>;
    }
  };

  /**
   * signatures
   */
  showSignatures = () => {
    if (this.state.contract && this.state.contract.statusCode > 1) {
      return (
        <div id="signatures">
          <div className="col">
            <div className="signature">
              <img src={this.state.contract.signature} alt="Signature" />
            </div>
            <div className="contract-line left">
              <div className="contract-name">
                Name: {this.state.contract.legalFirstName} {this.state.contract.legalLastName}
                {this.editYourSignature()}
              </div>
              <div className="contract-date">Date: {moment(this.state.contract.signedAt).format('MM/DD/YYYY')}</div>
            </div>
          </div>
          <div className="col">
            <div className="signature">{this.partnerSig()}</div>
            <div className="contract-line right">
              <div className="contract-name">
                Name: {this.state.contract.partnerLegalFirstName} {this.state.contract.partnerLegalLastName}
              </div>
              <div className="contract-date">
                Date: {moment(this.state.contract.partnerSignedAt).format('MM/DD/YYYY')}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  /**
   * partner sig
   */

  partnerSig = () => {
    if (this.state.contract.partnerSignature) {
      return <img src={this.state.contract.partnerSignature} alt="Partner Signature" />;
    }
  };

  /**
   * drawer methods
   */
  editSignature = () => {
    this.setState({ drawerContent: 'editSignature', visible: true, title: 'Edit Signature' });
  };

  edit = () => {
    this.setState({ drawerContent: 'editContract', visible: true, title: 'Edit Contract' });
  };

  reviewChanges = () => {
    this.setState({ drawerContent: 'reviewChanges', visible: true, title: 'Review Changes' });
  };

  requestSignature = () => {
    this.setState({ drawerContent: 'requestSignature', visible: true, title: 'Request Signature' });
  };

  /**
   * Drawer content
   */
  drawerContent = () => {
    if (this.state.drawerContent === 'editContract') {
      return (
        <Edit
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
        />
      );
    }

    if (this.state.drawerContent === 'editSignature') {
      return (
        <SignatureForm
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
          title={this.state.title}
          closeDrawerAndOpenSendRequest={this.closeDrawerAndOpenSendRequest}
        />
      );
    }

    if (this.state.drawerContent === 'reviewChanges') {
      return (
        <ReviewChanges
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
          title={this.state.title}
        />
      );
    }

    if (this.state.drawerContent === 'requestSignature') {
      return (
        <RequestSignature
          contract={this.state.contract}
          closeDrawerAndUpdateView={this.closeDrawerAndUpdateView}
          closeDrawer={this.closeDrawer}
          visible={this.state.visible}
          title={this.state.title}
          finishedOpenModal={this.finishedOpenModal}
        />
      );
    }
  };

  /**
   * callbacks for drawer
   */
  closeDrawerAndUpdateView = () => {
    this.setState({ visible: false }, () => {
      this.fetchContract();
    });
  };

  /**
   * Scope creep
   */
  finishedOpenModal = () => {
    /**
     * scope creep again
     */
    // this.setState({ visible: false }, () => {
    //   this.props.history.push('/dashboard');
    // });
    this.setState({ visible: false }, () => {
      this.setState({ modalVisible: true });
      this.fetchContract();
    });
  };

  closeDrawerAndOpenSendRequest = () => {
    this.fetchContract();
    this.requestSignature();
  };

  closeDrawer = () => {
    this.setState({ visible: false }, () => {
      setTimeout(() => {
        this.setState({ drawerContent: null });
      }, 1000);
    });
  };

  renderHeader = () => {
    if (this.state.contract) {
      return <ContractHeader statusCode={this.state.contract.statusCode} partner={this.state.partner} />;
    } else {
      return '';
    }
  };

  renderBody = () => {
    if (this.state.contract) {
      var contract = this.state.contract;
      return (
        <div className="contract-body">
          <ContractView contract={contract} />
          {this.showSignatures()}
        </div>
      );
    }
  };

  /**
   * modal actions
   */
  primaryButtonAction = () => {
    this.setState({ modalVisible: false }, () => {
      this.props.history.push('/dashboard');
    });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };
  render() {
    return (
      <div>
        <div id="contract">
          {this.drawerContent()}
          {this.renderHeader()}
          <div className="paper">
            <img className="paw" src={PawPrint} alt="paw" />
            <img className="float-right contract-logo" src={Logo} alt="logo" />
            <div className="contract-content">{this.renderBody()}</div>
          </div>
          <PPModal
            modalVisible={this.state.modalVisible}
            primaryButtonAction={this.primaryButtonAction}
            hideModal={this.hideModal}
          >
            <img src={docClock} alt="doc" />
            That’s it for now! We’ve let your partner know they need to read and sign the contract.
          </PPModal>
          {this.modalFooter()}
        </div>
      </div>
    );
  }
}
export default ShowContract;
